import React, { useCallback, useState } from 'react';
import { SM } from '@zendeskgarden/react-typography';
import { Formik } from 'formik';
import { FormInput, Form } from 'theme/Form';
import { Row, Col } from '@zendeskgarden/react-grid';
import { FullFormFieldWrapper } from 'theme/AuthForms.styles';
import { useBranch } from 'baobab-react/hooks';
import { Button } from 'theme/Button';
import Editor from 'draft-js-plugins-editor';
import createLinkifyPlugin from 'draft-js-linkify-plugin';
import createToolbarPlugin from 'draft-js-static-toolbar-plugin';
import createLinkPlugin from 'draft-js-anchor-plugin';
import {
  createEngagement,
  updateEngagement,
  deleteEngagement,
} from 'state/crm/actions';
import { getRawDraft } from 'utility/draftjsUtilities';
import { get, map } from 'lodash';
import AutoCompleteFormItem from 'components/MultiTypeForm/components/MultiSelectFormItem/AutoCompleteFormItem';
import { convertFromRaw, EditorState } from 'draft-js';
import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
  UnorderedListButton,
  OrderedListButton,
} from 'draft-js-buttons';
import { variables } from 'theme/variables';
import { formProps } from 'propTypesObjects';
import { getRole } from 'utility/hasAccount';
import { Wrapper, WrapperInner } from './CampaignEmailSettingsForm.styles';

const { color_red_400: colorRed400, custom_red: customRed } = variables;

function EngagementForm({
  onDelete,
  disabled,
  engagementId,
  canContinue,
  data = {},
  onSuccess,
  companyId,
}) {
  const { value: role } = getRole() || {};
  const [deletingStatus, setDeletingStatus] = useState({});
  const [localEngagmentId, setLocalEngagmentId] = useState(engagementId);
  const [editorState, setEditorState] = useState(
    data.json?.draft
      ? EditorState.createWithContent(convertFromRaw(data.json.draft))
      : EditorState.createEmpty()
  );
  const { rfqMeta, clientId } = useBranch({
    rfqMeta: ['requestForQuote', 'meta', 'result'],
    clientId: [
      'authentication',
      'session',
      'roles',
      role,
      'data',
      'company',
      'id',
    ],
  });
  const priorityOptions = get(rfqMeta, 'priority');

  const submitEngagement = useCallback(
    async (values, { setSubmitting, setStatus }) => {
      setSubmitting(true);
      const outData = { ...values, json: getRawDraft(editorState) };
      outData.company_ids = [];
      if (companyId) {
        outData.company_ids.push(+companyId);
      }
      outData.priority = outData.priority.value;
      const saveEngagementResult = localEngagmentId
        ? await updateEngagement(localEngagmentId, outData)
        : await createEngagement(outData);
      const { error } = saveEngagementResult;
      if (error) {
        setStatus({ error });
      } else {
        setLocalEngagmentId(saveEngagementResult.id);
        if (onSuccess) {
          onSuccess(saveEngagementResult);
        }
      }
      setSubmitting(false);
    },
    [companyId, editorState, localEngagmentId, onSuccess]
  );

  const onDeleteEngagement = useCallback(async () => {
    setDeletingStatus({ loading: true });
    await deleteEngagement(data.id);
    setDeletingStatus({ loading: false, deleted: true });
    if (onDelete) {
      onDelete();
    }
  }, [data.id, onDelete]);

  return (
    <Formik
      initialValues={{
        title: data.title || '',
        priority: data.priority
          ? { value: data.priority, label: data.priority_name }
          : '',
        company_ids: data.company_ids || '',
      }}
      onSubmit={submitEngagement}
    >
      {(props) => (
        <FormRendered
          engagementId={localEngagmentId}
          onDelete={onDeleteEngagement}
          deletingStatus={deletingStatus}
          // LINT OVERRIDE #3
          // Component wraps another component
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          priorityOptions={priorityOptions}
          disabled={disabled}
          canContinue={canContinue}
          {...{ editorState, setEditorState, clientId }}
        />
      )}
    </Formik>
  );
}

EngagementForm.defaultProps = {
  onDelete: undefined,
  disabled: undefined,
  engagementId: undefined,
  canContinue: undefined,
  data: undefined,
  onSuccess: undefined,
  companyId: undefined,
};

EngagementForm.propTypes = {
  ...formProps.generalForm.propTypes,
};

function FormRendered({
  engagementId,
  onDelete,
  disabled,
  editorState,
  setEditorState,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting,
  status,
  setFieldValue,
  priorityOptions,
  deletingStatus,
}) {
  return (
    <Form size="large" onSubmit={handleSubmit}>
      <Row>
        <Col size="6">
          <FullFormFieldWrapper spacer spacing="0px">
            <SM bold navy scaled tag="span">
              <span style={{ color: customRed }}>*</span> Engagement Name
            </SM>
            <FormInput
              type="text"
              id="title"
              name="title"
              required
              disabled={disabled}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.title}
              showMessage
              error={errors.name && touched.name && errors.name}
              placeholder="Enter Engagement Name"
            />
          </FullFormFieldWrapper>
        </Col>
        <Col size="6">
          <FullFormFieldWrapper spacer spacing="0px">
            <SM bold navy scaled tag="span">
              Priority
            </SM>
            <AutoCompleteFormItem
              style={{ marginTop: 10 }}
              defaultValue="Select Priority"
              optionValues={map(priorityOptions, (item) => ({
                value: item.id,
                label: item.name,
              }))}
              selectedItem={values.priority}
              setSelectedValue={(value) => {
                setFieldValue('priority', value);
              }}
              active={values.priority !== ''}
            />
          </FullFormFieldWrapper>
        </Col>
      </Row>
      <Row>
        <Col size="12">
          <FullFormFieldWrapper spacer spacing="0px">
            <SM bold navy scaled>
              Engagement Description
            </SM>
            <InputContainer
              disabled={disabled}
              {...{ editorState, setEditorState }}
            />
          </FullFormFieldWrapper>
        </Col>
      </Row>

      <Row justifyBetween style={{ marginTop: 20 }}>
        <Col alignCenter>
          {status && status.error ? (
            <div>
              <SM error validation="error">
                {status.error}
              </SM>
            </div>
          ) : null}
          <Button
            loading={isSubmitting || undefined}
            type="submit"
            disabled={disabled}
            buttonStatus
            error={status && status.error}
            primary
            style={{ marginTop: '0px', marginBottom: 0 }}
          >
            Save Engagement
          </Button>
        </Col>
        {engagementId ? (
          <Col alignCenter size="auto">
            <Button
              color={colorRed400}
              link
              loading={deletingStatus.loading}
              buttonStatus
              error={deletingStatus.error}
              onClick={onDelete}
            >
              Delete Engagement
            </Button>
          </Col>
        ) : null}
      </Row>
    </Form>
  );
}

FormRendered.defaultProps = {
  deletingStatus: undefined,
  onDelete: undefined,
  disabled: undefined,
  editorState: undefined,
  setEditorState: undefined,
  values: undefined,
  errors: undefined,
  touched: undefined,
  handleChange: undefined,
  handleBlur: undefined,
  handleSubmit: undefined,
  isSubmitting: undefined,
  status: undefined,
  setFieldValue: undefined,
  priorityOptions: undefined,
  engagementId: undefined,
};

FormRendered.propTypes = {
  ...formProps.formRendered.propTypes,
  ...formProps.generalForm.propTypes,
};

export default EngagementForm;

export class InputContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rendered: true,
    };
    this.onEditorStateChange = this.onEditorStateChange.bind(this);
    this.editor = React.createRef();
  }

  componentDidMount() {
    this.linkifyPlugin = createLinkifyPlugin();
    this.staticToolbarPlugin = createToolbarPlugin();
    this.linkPlugin = createLinkPlugin();

    this.plugins = [
      this.linkifyPlugin,
      this.staticToolbarPlugin,
      this.linkPlugin,
    ];
    // force re render
    this.setState({ rendered: true });
  }

  onEditorStateChange = (editorState) => {
    const { disabled, setEditorState } = this.props;
    if (disabled) {
      return;
    }
    setEditorState(editorState);
  };

  render() {
    const { rendered } = this.state;
    const { editorState, placeholder, disableToolbar } = this.props;
    const { linkPlugin } = this;
    const Toolbar =
      this.staticToolbarPlugin && this.staticToolbarPlugin.Toolbar;
    return (
      <Wrapper
        className="editor-input-container-wrapper"
        onClick={(e) => e.stopPropagation()}
      >
        <WrapperInner>
          {rendered && this.plugins ? (
            <div>
              <Editor
                placeholder={placeholder || 'Enter Engagement Description'}
                editorState={editorState}
                onChange={this.onEditorStateChange}
                plugins={this.plugins}
                ref={this.editor}
                spellCheck
              />
              {!disableToolbar ? (
                <Toolbar>
                  {(externalProps) => (
                    <div>
                      <BoldButton
                        // LINT OVERRIDE #3
                        // Component wraps another component
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...externalProps}
                      />
                      <ItalicButton
                        // LINT OVERRIDE #3
                        // Component wraps another component
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...externalProps}
                      />
                      <UnderlineButton
                        // LINT OVERRIDE #3
                        // Component wraps another component
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...externalProps}
                      />
                      <UnorderedListButton
                        // LINT OVERRIDE #3
                        // Component wraps another component
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...externalProps}
                      />
                      <OrderedListButton
                        // LINT OVERRIDE #3
                        // Component wraps another component
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...externalProps}
                      />
                      <linkPlugin.LinkButton
                        // LINT OVERRIDE #3
                        // Component wraps another component
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...externalProps}
                      />
                    </div>
                  )}
                </Toolbar>
              ) : null}
            </div>
          ) : null}
        </WrapperInner>
      </Wrapper>
    );
  }
}

InputContainer.defaultProps = {
  disabled: undefined,
  editorState: undefined,
  setEditorState: undefined,
  placeholder: undefined,
  disableToolbar: undefined,
};

InputContainer.propTypes = formProps.inputContainer;
