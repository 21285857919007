import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-date-picker';
import moment from 'moment';
import { isDate } from 'lodash';

import DatePickerWrapper from './DatePicker.styles';

function DatePickerComponent({
  date: dateProp,
  minDate: minDateProp,
  onChange,
  validation,
  medium,
  smallTable,
  onKeyDown,
}) {
  const [date, setDate] = useState(null);

  const minDate = minDateProp
    ? new Date(moment().format())
    : new Date(1974, 12, 1);

  const handleChange = useCallback(
    (_date) => {
      if (isDate(_date)) {
        if (onChange) {
          onChange(_date);
        } else {
          setDate(_date);
        }
      } else {
        onChange(null);
      }
    },
    [onChange]
  );

  const _date = dateProp || date;

  return (
    <DatePickerWrapper
      validation={validation}
      medium={medium}
      className={
        smallTable
          ? 'date-picker-styled-wrapper small-table'
          : 'date-picker-styled-wrapper'
      }
    >
      <DatePicker
        maxDetail="month"
        minDate={minDate}
        onChange={handleChange}
        value={_date}
        onKeyDown={onKeyDown}
      />
    </DatePickerWrapper>
  );
}

DatePickerComponent.propTypes = {
  date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  minDate: PropTypes.instanceOf(Date),
  onChange: PropTypes.func,
  validation: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  medium: PropTypes.bool,
  smallTable: PropTypes.bool,
  onKeyDown: PropTypes.func,
};

DatePickerComponent.defaultProps = {
  date: undefined,
  minDate: undefined,
  onChange: undefined,
  validation: undefined,
  medium: undefined,
  smallTable: undefined,
  onKeyDown: undefined,
};

export default DatePickerComponent;
