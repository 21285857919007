import fetchJson from 'utility/fetchJson';
import { isEmpty, cloneDeep } from 'lodash';
import { getValuesForRequest } from 'utility/reverseUrlFilters';
import { BASE } from '../../constants';

export async function exportCompanies({ filters, sorting = {} }) {
  let filtersCopy = cloneDeep(filters);
  filtersCopy = !filtersCopy ? getValuesForRequest('companies') : filtersCopy;
  const body = { ...filtersCopy, ...sorting };
  const response = await fetchJson(
    `${BASE}/api/company/list/export/`,
    {
      method: 'POST',
      body: JSON.stringify(body),
    },
    {},
    'blob'
  );
  return response;
}

export async function getCompanies({ filters, pagination, sorting = {} }) {
  let filtersCopy = cloneDeep(filters);
  filtersCopy = !filtersCopy ? getValuesForRequest('companies') : filtersCopy;
  const body = { ...filtersCopy, ...pagination, ...sorting };
  const response = await fetchJson(`${BASE}/api/company/list/`, {
    method: 'POST',
    body: JSON.stringify(isEmpty(body) ? { page: 1 } : body),
  });
  return response;
}

export async function getCompanyDetails(id) {
  const response = await fetchJson(`${BASE}/api/company/${id}/`, {
    method: 'GET',
  });
  return response;
}

export async function submitCompany(data) {
  const response = await fetchJson(`${BASE}/api/company/`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
  return response;
}

export async function updateCompany(companyId, data) {
  const response = await fetchJson(`${BASE}/api/company/${companyId}/`, {
    method: 'PATCH',
    body: JSON.stringify(data),
  });
  return response;
}

export async function deleteCompany(companyId, data) {
  const response = await fetchJson(`${BASE}/api/company/${companyId}/`, {
    method: 'PATCH',
    body: JSON.stringify(data),
  });
  return response;
}

export async function getCompanyTypes() {
  const response = await fetchJson(`${BASE}/api/company/type/`, {
    method: 'GET',
  });
  return response;
}

export async function getCompanyEngagements({
  filters,
  pagination,
  sorting = {},
}) {
  const filtersCopy = !filters ? getValuesForRequest('companies') : filters;
  const body = { ...filtersCopy, ...pagination, ...sorting };
  const response = await fetchJson(`${BASE}/api/crm/engagement/list/`, {
    method: 'POST',
    body: JSON.stringify(isEmpty(body) ? { page: 1 } : body),
  });
  return response;
}

export async function getCompanyAddress(companyId) {
  const response = await fetchJson(
    `${BASE}/api/company/address/${companyId}/`,
    {
      method: 'GET',
    }
  );
  return response;
}
export async function saveCompanyAddress({ data }) {
  const response = await fetchJson(`${BASE}/api/company/address/`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
  return response;
}
export async function updateCompanyAddress(id, { data }) {
  const response = await fetchJson(`${BASE}/api/company/address/${id}/`, {
    method: 'PATCH',
    body: JSON.stringify(data),
  });
  return response;
}
export async function deleteCompanyAddress(addressId) {
  const response = await fetchJson(
    `${BASE}/api/company/address/${addressId}/`,
    {
      method: 'DELETE',
    }
  );
  return response;
}
export async function getCompanyPhoneNumber(phoneId) {
  const response = await fetchJson(`${BASE}/api/company/phone/${phoneId}/`, {
    method: 'GET',
  });
  return response;
}
export async function saveCompanyPhoneNumber({ data }) {
  const response = await fetchJson(`${BASE}/api/company/phone/`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
  return response;
}
export async function deleteCompanyPhoneNumber(phoneId) {
  const response = await fetchJson(`${BASE}/api/company/phone/${phoneId}/`, {
    method: 'DELETE',
  });
  return response;
}

export async function updateCompanyPhoneNumber(id, { data }) {
  const response = await fetchJson(`${BASE}/api/company/phone/${id}/`, {
    method: 'PATCH',
    body: JSON.stringify(data),
  });
  return response;
}

// MANAGEMENT
export async function mergeCompanies(data = {}) {
  const response = await fetchJson(`${BASE}/api/company/merge/`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
  return response;
}

export async function deleteCompanies(data = {}) {
  const response = await fetchJson(`${BASE}/api/company/delete/`, {
    method: 'DELETE',
    body: JSON.stringify(data),
  });
  return response;
}
