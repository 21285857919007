import fetchJson from 'utility/fetchJson';
import { identify } from 'utility/analytics';
import * as Sentry from '@sentry/react';
import { BASE } from '../../constants';

export async function getToken() {
  let token = await fetch(`${BASE}/api/auth/token/`);
  token = await token.json();
  return token;
}

export async function fetchEmailTokenUser({ cipher, token }) {
  const query =
    cipher && token
      ? `?token=${token}&cipher=${encodeURIComponent(cipher)}`
      : '';
  const response = await fetchJson(`${BASE}/api/email-token/user/${query}`, {
    method: 'GET',
  });
  return response;
}

export function identifyUser(session) {
  if (session) {
    identify(session.user_id, {
      first_name: session.first_name,
      last_name: session.last_name,
      email: session.email,
      user_id: session.user_id,
    });
    Sentry.configureScope((scope) => {
      scope.setUser({ email: session.email, user_id: session.user_id });
    });
  }
}

export async function createAccount(data) {
  const response = await fetchJson(`${BASE}/api/on-board/register/`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
  identifyUser(response);
  return response;
}

export async function signIn(data) {
  const response = await fetchJson(`${BASE}/api/auth/login/`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
  identifyUser(response);
  return response;
}

export async function logout() {
  const response = await fetchJson(`${BASE}/api/auth/logout/`, {
    method: 'POST',
  });
  return response;
}

export async function forgotPassword(data) {
  const response = await fetchJson(`${BASE}/api/auth/password/reset/`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
  return response;
}

export async function checkSession() {
  const response = await fetchJson(`${BASE}/api/auth/session/`, {
    method: 'GET',
  });
  return response;
}

export async function recoverPassword(data) {
  const response = await fetchJson(`${BASE}/api/on-board/recover/password/`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
  return response;
}

export async function resetPassword(key, data) {
  const response = await fetchJson(`${BASE}/api/on-board/reset/password/`, {
    method: 'POST',
    body: JSON.stringify({ ...data, key }),
  });
  return response;
}

export async function confirmEmail(key) {
  const response = await fetchJson(
    `${BASE}/api/on-board/register/confirm/?key=${key}`,
    {
      method: 'GET',
    }
  );
  return response;
}

export async function confirmEmailResend() {
  const response = await fetchJson(
    `${BASE}/api/on-board/resend/confirmation/`,
    {
      method: 'POST',
    }
  );
  return response;
}

export async function changeEmail(data) {
  const response = await fetchJson(`${BASE}/api/on-board/change/email`, {
    // TODO
    method: 'POST',
    body: JSON.stringify(data),
  });
  return response;
}

export async function changePassword(data) {
  const response = await fetchJson(`${BASE}/api/on-board/change/password`, {
    // TODO
    method: 'POST',
    body: JSON.stringify(data),
  });
  return response;
}
