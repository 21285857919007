import { debounce, keys, reduce, get, omitBy, isNil } from 'lodash';

import tree from 'state';
import asyncTreeRequester from 'utility/asyncTreeRequester';
import { setValues } from 'utility/urlUtils';
import {
  generateItemFilters,
  generateFilterValues,
} from 'state/requestForQuote/transformer';
import {
  generateCriteriaQueryFilters,
  CRITERIA_QUERY_VALUES,
} from 'state/requestForQuote/transformer_criteria';
import filterFacetsWithCriteria from 'utility/filterFacetsWithCriteria';
import { generateFiltersObject } from 'utility/reverseUrlFilters';
import downloadFileFromBlob from 'utility/downloadFile';
import * as effects from './effects';

const listingsCursor = tree.select(['listings']);

const DEBOUNCE_TIME = 400;

export async function getListings({
  pagination,
  filters,
  sorting,
  criteria = {},
} = {}) {
  await asyncTreeRequester({
    func: effects.getListings.bind(this, { criteria }),
    pagination,
    filters,
    sorting,
    cursor: listingsCursor,
    path: ['list'],
  });
}

const debounceListingSearch = debounce(getListings, DEBOUNCE_TIME);

export async function getListingDetails(id, refresh) {
  await asyncTreeRequester({
    func: effects.getListingDetails.bind(this, id, refresh),
    cursor: listingsCursor,
    path: ['listingDetails', id],
  });
}

export async function getSimilarListings({
  pagination,
  filters,
  sorting,
  id,
  criteria,
} = {}) {
  const safeId = id || 0;
  await asyncTreeRequester({
    func: effects.getSimilarListings.bind(this, { id: safeId, criteria }),
    pagination,
    filters,
    sorting,
    cursor: listingsCursor,
    path: ['similarListings', id || 'global'],
  });
}

export function onSearchListingsChange(branch, type, data, options = {}) {
  const currentFilters = listingsCursor.get([
    'itemFilterWrapper',
    'itemFilters',
  ]);
  let facetFilters = listingsCursor.get(['list', 'filters']);
  facetFilters = { ...(facetFilters || {}), ...(options.filters || {}) };

  const { filters, criteria, itemFilters, attribute } =
    filterFacetsWithCriteria({
      type,
      data,
      options,
      currentFilters,
      facetFilters,
    });
  const mergedValues = { ...criteria, ...filters };
  listingsCursor.set(['list', 'filters'], mergedValues);
  if (itemFilters) {
    listingsCursor.set(
      ['itemFilterWrapper', 'itemFilters', attribute],
      itemFilters
    );
  }
  if (criteria) {
    debounceListingSearch({ criteria, filters });
    // This is due to global interest filters component setting the url params automatically for sidebar filters. we need to set them for the input filters
    if (!options.external) {
      const urlQueryParams = generateFiltersObject(mergedValues);
      setValues(urlQueryParams, [
        ...CRITERIA_QUERY_VALUES,
        ...keys(facetFilters),
      ]);
    }
  }
}

// WE NEED TO REFACTOR THIS COMPONENT -- WE REUSE THIS PATTERN IN A LOT OF PLACES -- WORKING ON HOOKS IMPLEMENTATION TO MOVE AWAY FROM --- setActiveRfqItem

export async function setActiveRfqItem(seed = {}) {
  listingsCursor.set(
    ['itemFilterWrapper', 'itemFilters'],
    generateItemFilters(null, seed, {
      range: { multiple: true },
      category: { multiple: true },
      hidden: { quantity: true },
    })
  );
}

export function prepareCriteria({ ignoreOmit } = {}) {
  let filterData = generateFilterValues(
    listingsCursor.get(['itemFilterWrapper', 'itemFilters'])
  );
  filterData = generateCriteriaQueryFilters(filterData);
  if (!ignoreOmit) {
    filterData = omitBy(filterData, (d) => isNil(d) || get(d, 'length') === 0);
  }
  return filterData;
}

export function clearCriteriaSearch() {
  const keyList = keys(prepareCriteria({ ignoreOmit: true }));
  const filters = reduce(
    keyList,
    (accum, item) => {
      const _accum = accum;
      _accum[item] = undefined;
      return _accum;
    },
    {}
  );
  setValues(filters, keyList);
  setActiveRfqItem();
}

export async function getCuratedListingsListHtml(ids) {
  try {
    const response = await effects.getCuratedListingsListHtml(ids);
    return response;
  } catch (err) {
    return {
      error: err.message,
    };
  }
}

export async function createCuratedListingsList(ids) {
  try {
    const blob = await effects.createCuratedListingsList(ids);
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth() + 1;
    const day = now.getDate();
    downloadFileFromBlob(blob, `CuratedListings_${year}-${month}-${day}.pdf`);
    return {
      success: true,
    };
  } catch (err) {
    return {
      error: err.message,
    };
  }
}
