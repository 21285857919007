import * as Sentry from '@sentry/react';

const URL_MAPPER_PRE = {
  RFQ: 'dashboard/opportunities',
  SupplyOpportunity: 'dashboard/supply/group',
  SupplyOpportunityItem: 'dashboard/supply-item',
  AuthUser: 'dashboard/users',
  Company: 'dashboard/companies',
  Campaign: 'dashboard/campaigns',
  Engagement: 'dashboard/engagements',
  AttributeMetaValue: 'dashboard/attributes',
  CampaignRecipients: 'dashboard/campaigns',
  AttributeMetaItemValue: 'dashboard/attributes',
  AttributeMetaSubCategory: 'settings/attributes',
  AttributeMetaCategory: 'settings/attributes',
  ListingPage: 'dashboard/listings',
};

const URL_MAPPER_POST = {
  RFQ: 'information',
  SupplyOpportunity: 'details',
  SupplyOpportunityItem: 'details',
  AuthUser: 'details',
  Company: 'details',
  Campaign: 'details',
  Engagement: 'interactions',
  AttributeMetaValue: 'details',
  CampaignRecipients: 'recipients',
  AttributeMetaItemValue: 'items',
  AttributeMetaSubCategory: '',
  AttributeMetaCategory: '',
  ListingPage: 'preview',
};

const NO_ID = {
  AttributeMetaSubCategory: true,
  AttributeMetaCategory: true,
};

const USE_FOREIGN_ID = {
  RFQ: false,
  SupplyOpportunity: false,
  SupplyOpportunityItem: false,
  AuthUser: false,
  Company: false,
  Campaign: false,
  Engagement: false,
  AttributeMetaValue: false,
  CampaignRecipients: true,
  AttributeMetaItemValue: true,
  ListingPage: false,
};

// Generate urls for notification objects
function objectToUrl(object) {
  const objectType = object.object_type;
  const id = object.object_id;
  const foreignId = object.foreign_id;

  if (!URL_MAPPER_PRE[objectType]) {
    Sentry.captureMessage(
      `Notification generated for unknown object type : '${objectType}''`
    );
    return '/dashboard';
  }

  let url = `/${URL_MAPPER_PRE[objectType]}/`;
  if (!NO_ID[objectType]) {
    url += USE_FOREIGN_ID[objectType] ? foreignId : id;
  }

  if (URL_MAPPER_POST[objectType]) {
    url += `/${URL_MAPPER_POST[objectType]}`;
  }

  return url;
}

export default objectToUrl;
