import tree from 'state';
import { findIndex, cloneDeep, pick, reject, isEmpty } from 'lodash';
import asyncTreeRequester from 'utility/asyncTreeRequester';
import { onboardingValueExtractor } from 'utility/dynamicFormHelpers';
import * as opportunityEffects from 'state/opportunities/effects';
import * as supplyEffects from 'state/supplyInterests/effects';
import * as customerEffects from 'state/customers/effects';
import * as wishlistInterestsEffects from 'state/wishlistInterests/effects';
import downloadFileFromBlob from 'utility/downloadFile';
import { generateCompany } from './transformer_companies';
import * as effects from './effects';

const companiesCursor = tree.select(['companies']);

export async function getMyCompanies({
  pagination,
  filters,
  sorting,
  grouping,
} = {}) {
  return asyncTreeRequester({
    func: effects.getCompanies.bind(this),
    pagination,
    filters,
    sorting,
    grouping,
    cursor: companiesCursor,
    path: ['currentCompanies'],
  });
}

export async function getCompanyDetails(id) {
  return asyncTreeRequester({
    func: effects.getCompanyDetails.bind(this, id),
    cursor: companiesCursor,
    path: ['activeCompany', 'details'],
  });
}

export async function setCompanyInfo(basePath, category, type, data) {
  const dataCopy = data;
  const path = [...basePath, category, 'fields', type];
  const item = companiesCursor.get(path);
  dataCopy.dirty = true;
  dataCopy.valid = item.validation
    ? item.validation(dataCopy.value, item.required)
    : true;
  companiesCursor.merge(path, dataCopy);
  companiesCursor.set([category, 'dirty'], true);
  tree.commit();
}

export async function exportCompanies({ filters, sorting } = {}) {
  return asyncTreeRequester({
    func: effects.exportCompanies.bind(this),
    filters,
    sorting,
    cursor: companiesCursor,
    path: ['list', 'export'],
    onSuccess: (blob) => {
      downloadFileFromBlob(blob, 'companies.xlsx');
    },
  });
}

export async function setSidebarCompany(company, { reset } = {}) {
  let companyCopy = company ? cloneDeep(company) : {};
  if (reset !== false) {
    companiesCursor.set(['activeCompany'], {});
  }
  const companyId = companyCopy
    ? companyCopy.company || companyCopy.pk || companyCopy.id
    : null;
  if (!isEmpty(companyCopy)) {
    companyCopy = await getCompanyDetails(companyId);
    if (
      companyCopy.description === null &&
      companyCopy.linkedin_description !== null
    ) {
      companyCopy.description = companyCopy.linkedin_description;
    }
    companiesCursor.set(
      ['activeCompany', 'companyDetails'],
      companyCopy.error ? companyCopy : { result: companyCopy }
    );
  }
  const companyFormData = generateCompany({
    seedData: companyCopy,
  });
  companyFormData.companyId = companyId;
  companiesCursor.set(['activeCompany', 'companyFormData'], companyFormData);
  tree.commit();
}

export async function patchCompanyDetailsPage(data) {
  try {
    if (companiesCursor.get(['activeCompany', 'details', 'result'])) {
      companiesCursor.merge(['activeCompany', 'details', 'result'], data);
    }
    return data;
  } catch (err) {
    return err.message;
  }
}

export async function getCompanyAddress(id) {
  return asyncTreeRequester({
    func: effects.getCompanyAddress.bind(this, id),
    cursor: companiesCursor,
    path: ['activeCompany', 'addresses'],
  });
}

export async function resetCompanyDetails() {
  companiesCursor.set(['activeCompany', {}]);
}

export async function saveCompanyAddress(id, { data }) {
  try {
    const address = id
      ? await effects.updateCompanyAddress(id, { data })
      : await effects.saveCompanyAddress({ data });
    const addresses =
      companiesCursor.get([
        'activeCompany',
        'companyDetails',
        'result',
        'addresses',
      ]) || [];
    if (!id) {
      companiesCursor.set(
        ['activeCompany', 'companyDetails', 'result', 'addresses'],
        [...addresses, address]
      );
    } else {
      const clonedAddresses = cloneDeep(addresses);
      const matchingAddress = findIndex(clonedAddresses, { id });
      clonedAddresses[matchingAddress] = address;
      companiesCursor.set(
        ['activeCompany', 'companyDetails', 'result', 'addresses'],
        clonedAddresses
      );
      patchCompanyDetailsPage({ addresses: clonedAddresses });
    }
    return address;
  } catch (err) {
    return { error: err.message };
  }
}

export async function deleteCompanyAddress(id) {
  try {
    const address = await effects.deleteCompanyAddress(id);
    const addresses =
      companiesCursor.get([
        'activeCompany',
        'companyDetails',
        'result',
        'addresses',
      ]) || [];
    const removedCompanies = reject(addresses, ['id', id]);
    companiesCursor.set(
      ['activeCompany', 'companyDetails', 'result', 'addresses'],
      removedCompanies
    );
    patchCompanyDetailsPage({ addresses: removedCompanies });
    return address;
  } catch (err) {
    return { error: err.message };
  }
}

export async function saveCompanyPhoneNumber(id, { data }) {
  try {
    const phoneNumber = id
      ? await effects.updateCompanyPhoneNumber(id, { data })
      : await effects.saveCompanyPhoneNumber({ data });
    const phoneNumbers =
      companiesCursor.get([
        'activeCompany',
        'companyDetails',
        'result',
        'phone',
      ]) || [];
    if (!id) {
      companiesCursor.set(
        ['activeCompany', 'companyDetails', 'result', 'phone'],
        [...phoneNumbers, phoneNumber]
      );
    } else {
      const clonedPhoneNumbers = cloneDeep(phoneNumbers);
      const matchingAddress = findIndex(clonedPhoneNumbers, { id });
      clonedPhoneNumbers[matchingAddress] = phoneNumber;
      companiesCursor.set(
        ['activeCompany', 'companyDetails', 'result', 'phone'],
        clonedPhoneNumbers
      );
      patchCompanyDetailsPage({ phone: clonedPhoneNumbers });
    }
    return phoneNumber;
  } catch (err) {
    console.log(err);
    return { error: err.message };
  }
}

export async function deleteCompanyPhoneNumber(id) {
  try {
    const phoneNumber = await effects.deleteCompanyPhoneNumber(id);
    const phoneNumbersList =
      companiesCursor.get([
        'activeCompany',
        'companyDetails',
        'result',
        'phone',
      ]) || [];
    const removedPhoneNumbers = reject(phoneNumbersList, ['id', id]);
    companiesCursor.set(
      ['activeCompany', 'companyDetails', 'result', 'phone'],
      removedPhoneNumbers
    );
    patchCompanyDetailsPage({ phone: removedPhoneNumbers });

    return phoneNumber;
  } catch (err) {
    return { error: err.message };
  }
}

export async function getCompanyOpportunities({
  pagination,
  filters,
  sorting,
} = {}) {
  await asyncTreeRequester({
    func: opportunityEffects.getOpportunities.bind(this, {}),
    pagination,
    filters,
    sorting,
    cursor: companiesCursor,
    path: ['activeCompany', 'currentOpportunities'],
  });
}

export async function getCompanySupplyInterests({
  pagination,
  filters,
  sorting,
} = {}) {
  await asyncTreeRequester({
    func: supplyEffects.getSupplyInterestsItems.bind(this, {}),
    pagination,
    filters,
    sorting,
    cursor: companiesCursor,
    path: ['activeCompany', 'supplyInterests'],
  });
}

export async function getCompanyEmployees({
  pagination,
  filters,
  sorting,
} = {}) {
  await asyncTreeRequester({
    func: customerEffects.getCustomers.bind(this),
    pagination,
    filters,
    sorting,
    cursor: companiesCursor,
    path: ['activeCompany', 'employees'],
  });
}

export async function getCompanyEngagements({
  pagination,
  filters,
  sorting,
} = {}) {
  await asyncTreeRequester({
    func: effects.getCompanyEngagements.bind(this),
    pagination,
    filters,
    sorting,
    cursor: companiesCursor,
    path: ['activeCompany', 'engagements'],
  });
}

export async function getCompanyWishlist({
  pagination,
  filters,
  sorting,
} = {}) {
  await asyncTreeRequester({
    func: wishlistInterestsEffects.getWishlistInterests.bind(this),
    pagination,
    filters,
    sorting,
    cursor: companiesCursor,
    path: ['activeCompany', 'wishlistItems'],
  });
}

export async function patchCompanyDetails(data) {
  try {
    companiesCursor.merge(['activeCompany', 'companyDetails', 'result'], data);
    return data;
  } catch (err) {
    return { error: err.message };
  }
}

export async function submitCompany(companyId) {
  const company = companiesCursor.get(['activeCompany', 'companyFormData']);
  const companyIdCopy = companyId || company.companyId;

  const values = onboardingValueExtractor(pick(company, 'company'));
  if (values.description === undefined) {
    values.description = null;
  }
  const companyDetails = await asyncTreeRequester({
    func: companyIdCopy
      ? effects.updateCompany.bind(this, companyIdCopy, values)
      : effects.submitCompany.bind(this, values),
    cursor: companiesCursor,
    path: ['activeCompany', 'companyDetails'],
    transformer: ({ result, cursor = {} }) => {
      const original = cursor.result || {};
      return { ...original, ...result };
    },
  });

  patchCompanyDetailsPage(companyDetails);

  return companyDetails;
}

export async function mergeCompanies(data) {
  try {
    const result = await asyncTreeRequester({
      func: effects.mergeCompanies.bind(this, data),
      handleResult: true,
    });
    return result;
  } catch (err) {
    return err.message;
  }
}

export async function deleteCompanies(data) {
  try {
    const result = await asyncTreeRequester({
      func: effects.deleteCompanies.bind(this, data),
      handleResult: true,
    });
    return result;
  } catch (err) {
    return err.message;
  }
}
