import React, { useState, useRef, useEffect, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import Flex from 'styled-flex-component';
import Icon from 'components/Icon/Icon';
import { SM } from '@zendeskgarden/react-typography';
import {
  HeaderItem,
  Autocomplete,
  Dropdown,
  Menu,
  Item,
  Field,
} from '@zendeskgarden/react-dropdowns';
import {
  isArray,
  get,
  capitalize,
  isObject,
  map,
  find,
  filter,
  has,
  debounce,
  toString,
  includes,
  toLower,
  noop,
  isNil,
  isEmpty,
  size as lodashSize,
} from 'lodash';
import { Ellipsis } from 'theme/Global.styles';
import { variables } from 'theme/variables';
import { downshiftAutocompleteChangeTypes } from '../../../../utility/staticMap.ts';

const { color_red_400: colorRed400 } = variables;
const { blurInput } = downshiftAutocompleteChangeTypes;

const DropDownStyle = { position: 'relative' };
const FieldStyle = { width: '100%' };

function FilterItemValues({
  defaultOptions,
  onBlur,
  hideAdd,
  showAdd,
  selectedValues,
  multiple,
  clearable,
  uom,
  warning,
  error,
  customValues,
  search,
  ignoreFilter,
  persistCasing,
  itemsSmall,
  defaultValue,
  selectedValue,
  setSelectedValue,
  title,
  optionValues,
  size,
  small,
  medium,
  semibold,
  colorOverride,
  minDropdownWidth,
  range,
  rangeKey,
  optionEllipsis,
  popperModifiers,
  itemSelection,
  fullBorder,
  maxHeight,
  menuZIndex,
}) {
  const hasCustomValues = multiple
    ? find(selectedValues, { customValue: true })
    : get(selectedValue, 'customValue');
  const options = useMemo(() => {
    let _options;
    if (defaultValue) {
      _options = [defaultValue, ...(optionValues || [])];
    } else {
      _options = optionValues || [];
    }
    if (hasCustomValues) {
      _options = multiple
        ? [..._options, ...filter(selectedValues, 'customValue')]
        : [..._options, selectedValue];
    }
    return _options;
  }, [
    defaultValue,
    hasCustomValues,
    multiple,
    optionValues,
    selectedValue,
    selectedValues,
  ]);

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [searching, setSearching] = useState(false);
  const [matchingOptions, setMatchingOptions] = useState(options);

  useEffect(() => {
    setMatchingOptions(options);
    setSearching(false);
  }, [options]);

  useEffect(() => {
    if (!isEmpty(search) && !menuIsOpen) {
      setInputValue('');
      setMatchingOptions([defaultValue]);
    } else if (!menuIsOpen) {
      setMatchingOptions(options);
    }
  }, [defaultValue, menuIsOpen, options, search]);

  let currentSelectedValues;
  if (multiple && selectedValues) {
    if (isArray(selectedValues)) {
      currentSelectedValues = selectedValues;
    } else {
      currentSelectedValues = [selectedValues];
    }
  } else {
    currentSelectedValues = selectedValues;
  }

  const hasValue =
    selectedValue && !isObject(selectedValue) && selectedValue !== '';
  const hasObjectValue =
    isObject(selectedValue) &&
    !isNil(selectedValue.value) &&
    selectedValue.value !== '';
  const hasSelectedValue = hasValue || hasObjectValue;
  const getSelectedValueLabel = () => {
    if (hasValue) {
      return selectedValue;
    }
    if (hasObjectValue) {
      return selectedValue.label;
    }
    return null;
  };

  const currentClearable =
    clearable &&
    (hasSelectedValue ||
      (isArray(currentSelectedValues) &&
        lodashSize(currentSelectedValues) > 0));

  async function searchMore(value) {
    // searching will be reset to false when options are populated - this ensures that "Searching..." is displayed for the user
    setSearching(true);
    await search(value);
  }

  const filterMatchingOptions = (value, _options) => {
    let filteredMatchingOptions = [];
    if (!ignoreFilter) {
      filteredMatchingOptions = filter(_options, (option) => {
        const currentOption = option.label || option || '';
        return defaultValue === currentOption
          ? defaultValue
          : includes(toLower(toString(currentOption)), toLower(value || '')) &&
              option !== defaultValue;
      });
      setMatchingOptions(filteredMatchingOptions);
    }
    if (
      !lodashSize(filteredMatchingOptions) &&
      search &&
      value &&
      lodashSize(value) > 1
    ) {
      searchMore(value);
    }
    if (
      lodashSize(defaultOptions) &&
      lodashSize(defaultOptions) > 0 &&
      !value
    ) {
      setMatchingOptions(defaultOptions);
    }
  };

  const filterMatchingOptionsRef = useRef(
    debounce(filterMatchingOptions, 1000)
  );

  useEffect(() => {
    filterMatchingOptionsRef.current(inputValue, options);
  }, [inputValue, options]);

  const renderOptions = (clearItems) => {
    const renderEmpty =
      lodashSize(matchingOptions) === 0 ||
      (lodashSize(matchingOptions) === 1 &&
        matchingOptions[0] === defaultValue);
    function renderOption(option, i) {
      const optionLabel = option.label || option;
      const value = persistCasing ? optionLabel : capitalize(optionLabel);
      const renderClear =
        option === defaultValue && currentClearable && hasSelectedValue;
      const checked = currentSelectedValues
        ? !!find(currentSelectedValues, { label: option.label })
        : !!selectedValue &&
          (isObject(selectedValue) ? option.value : option.label) ===
            (isObject(selectedValue) ? selectedValue.value : selectedValue);
      return (
        <div key={`${optionLabel}-${i}`}>
          {renderClear && getSelectedValueLabel() ? (
            <Item
              itemsSmall={itemsSmall}
              disabled
              key="selected-value-label"
              value=""
            >
              <Ellipsis>{getSelectedValueLabel()}</Ellipsis>
            </Item>
          ) : null}
          <Item
            clear={renderClear}
            checked={checked}
            itemsSmall={itemsSmall}
            disabled={option === defaultValue && !currentClearable}
            value={option}
          >
            {(() => {
              if (renderClear) {
                return (
                  <Ellipsis>
                    <Flex alignCenter justifyBetween>
                      <span style={{ color: colorRed400 }}>
                        Clear Selection
                      </span>
                      <Icon
                        fadeOnHover
                        pointer
                        icon="icon-close"
                        color={colorRed400}
                        fontSize="9px"
                      />
                    </Flex>
                  </Ellipsis>
                );
              }
              if (optionEllipsis) {
                return <Ellipsis>{value}</Ellipsis>;
              }
              return value;
            })()}
          </Item>
        </div>
      );
    }

    if (renderEmpty) {
      return (
        <>
          {customValues && inputValue ? (
            <>
              <HeaderItem key="custom-values" small disabled>
                <SM>Custom Values</SM>
              </HeaderItem>
              <Item
                key="custom-value"
                value={{
                  value: inputValue,
                  label: inputValue,
                  customValue: true,
                }}
                itemsSmall={itemsSmall}
              >
                {inputValue}
              </Item>
              <HeaderItem key="standard-values" small disabled>
                <SM>Standard Values</SM>
              </HeaderItem>
            </>
          ) : null}

          {search && hasSelectedValue ? (
            <div key="selected-value-clear-wrapper">
              {getSelectedValueLabel() ? (
                <Item
                  itemsSmall={itemsSmall}
                  disabled
                  key="selected-value-label-empty"
                  value=""
                >
                  <Ellipsis>{getSelectedValueLabel()}</Ellipsis>
                </Item>
              ) : null}
              <Item
                clear
                checked={false}
                itemsSmall={itemsSmall}
                key="selected-value"
                value=""
              >
                <Ellipsis>
                  <Flex alignCenter justifyBetween>
                    <span style={{ color: colorRed400 }}>Clear Selection</span>
                    <Icon
                      fadeOnHover
                      pointer
                      icon="icon-close"
                      color={colorRed400}
                      fontSize="9px"
                    />
                  </Flex>
                </Ellipsis>
              </Item>
            </div>
          ) : null}

          <Item key="no-matches" itemsSmall={itemsSmall} disabled>
            {(() => {
              if (search && !inputValue) {
                return `Enter ${title || ''} Search`;
              }
              if (searching) {
                return 'Searching...';
              }
              if (inputValue && lodashSize(inputValue) < 2) {
                return 'Enter more than one character';
              }
              return 'No matches found';
            })()}
          </Item>
        </>
      );
    }

    const currentSelection =
      currentSelectedValues || (selectedValue ? [selectedValue] : null);
    let _matchingOptions;
    if (!isEmpty(inputValue) && lodashSize(inputValue) < 1) {
      _matchingOptions = defaultOptions;
    } else {
      _matchingOptions = matchingOptions;
    }
    let optionList = clearItems ? [_matchingOptions[0]] : _matchingOptions;
    optionList =
      search && clearItems && lodashSize(currentSelectedValues)
        ? [...optionList, ...currentSelection]
        : optionList;
    return map(optionList, (option, i) => renderOption(option, i));
  };

  function setRangeValue(val, currentValue) {
    // MIN AND MAX VALUES
    let formattedVal = isObject(currentValue) ? { ...currentValue } : {}; // need to clone, dont mutate

    const inputVal = val;
    if (get(formattedVal, `${rangeKey}.value`) === val.value) {
      // if they are the same uncheck
      formattedVal = null;
    } else {
      formattedVal[rangeKey] = inputVal;
    }
    setSelectedValue(formattedVal);
  }

  function setSelectedValueLocally(item, { originalValue }) {
    let currentItem = item;
    if (multiple && isArray(currentItem)) {
      currentItem = filter(currentItem, (itm) => itm && itm.value); // We want make sure we can filter out the default value when multiple selected
    }
    if (currentItem === defaultValue) {
      setSelectedValue(null);
    } else if (
      currentItem.value === get(originalValue, 'value') &&
      get(originalValue, 'value')
    ) {
      setSelectedValue(null);
    } else {
      if (currentItem.description) {
        currentItem.value = currentItem.description;
      }
      setSelectedValue(currentItem);
    }
  }

  const originalValue = selectedValue;

  let selectedValueLabel;
  if (range && selectedValue) {
    // IE selectedValue = {min: {}}
    selectedValueLabel = selectedValue?.[rangeKey]?.label || '';
  } else if (has(selectedValue, 'label')) {
    selectedValueLabel = selectedValue.label;
  } else {
    // a string should be passed in here.
    selectedValueLabel = selectedValue;
  }

  const finalValue =
    multiple && lodashSize(currentSelectedValues)
      ? map(currentSelectedValues, 'label').join(', ')
      : selectedValueLabel || defaultValue;

  const selectedItem = multiple
    ? undefined
    : selectedValueLabel || defaultValue;
  const selectedItems = multiple
    ? currentSelectedValues || (defaultValue ? [defaultValue] : [])
    : undefined;

  const active = !!selectedValueLabel || !!lodashSize(currentSelectedValues);

  const renderClear = ignoreFilter
    ? (!inputValue || !lodashSize(inputValue)) &&
      (!defaultOptions || !lodashSize(defaultOptions))
    : false;

  return (
    <Dropdown
      style={DropDownStyle}
      inputValue={inputValue || ''}
      selectedItem={selectedItem}
      selectedItems={selectedItems}
      onStateChange={(changes) => {
        if (
          Object.prototype.hasOwnProperty.call(changes, 'inputValue') &&
          !includes(blurInput, changes.type)
        ) {
          setInputValue(changes.inputValue);
        } else if (
          Object.prototype.hasOwnProperty.call(changes, 'inputValue')
        ) {
          setInputValue('');
        }
        if (
          changes.isOpen !== menuIsOpen &&
          Object.prototype.hasOwnProperty.call(changes, 'isOpen')
        ) {
          setMenuIsOpen(changes.isOpen);
        }
      }}
      onSelect={(item) =>
        range
          ? setRangeValue(item, originalValue)
          : setSelectedValueLocally(item, { originalValue })
      }
      // MIN AND MAX VALUES
      downshiftProps={{
        defaultHighlightedIndex: selectedValueLabel && currentClearable ? 1 : 0,
        itemToString: (item) => (item && item.label) || item,
      }}
    >
      <Field style={FieldStyle}>
        <Autocomplete
          multiple={multiple}
          className={`${active ? 'active' : ''} filter-item-input`}
          warning={warning}
          error={error}
          range={range}
          uom={uom}
          ellipsis
          style={{ width: '100%' }}
          colorOverride={colorOverride}
          semibold={semibold}
          size={size}
          medium={medium}
          small={small}
          onBlur={onBlur}
          active={active}
          itemSelection={itemSelection !== false}
          fullBorder={fullBorder}
        >
          <Ellipsis title={finalValue} className="input-ellipsis">
            {persistCasing ? finalValue : capitalize(finalValue)}
          </Ellipsis>
          {multiple &&
          !hideAdd &&
          (lodashSize(currentSelectedValues) || showAdd) ? (
            <span className="add"> Add</span>
          ) : null}
        </Autocomplete>
      </Field>
      {menuIsOpen && optionValues ? (
        <Menu
          popperModifiers={popperModifiers}
          style={{ minWidth: minDropdownWidth || '200px' }}
          placement="bottom-start"
          maxHeight={maxHeight || '180px'}
          zIndex={menuZIndex}
        >
          {renderOptions(renderClear)}
        </Menu>
      ) : null}
    </Dropdown>
  );
}

FilterItemValues.propTypes = {
  defaultOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    })
  ),
  onBlur: PropTypes.func,
  hideAdd: PropTypes.bool,
  showAdd: PropTypes.bool,
  selectedValues: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    })
  ),
  multiple: PropTypes.bool,
  clearable: PropTypes.bool,
  uom: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  warning: PropTypes.string,
  error: PropTypes.bool,
  customValues: PropTypes.bool,
  search: PropTypes.func,
  // LINT OVERRIDE #6
  // Object has undetermined keys
  // eslint-disable-next-line react/forbid-prop-types
  ignoreFilter: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  persistCasing: PropTypes.bool,
  itemsSmall: PropTypes.bool,
  defaultValue: PropTypes.string,
  // LINT OVERRIDE #8
  // TECH DEBT
  // Array has undetermined elements
  // eslint-disable-next-line react/forbid-prop-types
  selectedValue: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string,
  ]),
  setSelectedValue: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  optionValues: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        label: PropTypes.string,
        rangeFilterValue: PropTypes.number,
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.bool,
        ]),
      })
    ),
    PropTypes.arrayOf(PropTypes.string),
  ]),
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  small: PropTypes.bool,
  medium: PropTypes.bool,
  semibold: PropTypes.bool,
  colorOverride: PropTypes.string,
  minDropdownWidth: PropTypes.string,
  range: PropTypes.bool,
  rangeKey: PropTypes.string,
  optionEllipsis: PropTypes.bool,
  // LINT OVERRIDE #6
  // Object has undetermined keys
  // eslint-disable-next-line react/forbid-prop-types
  popperModifiers: PropTypes.object,
  itemSelection: PropTypes.bool,
  fullBorder: PropTypes.bool,
  maxHeight: PropTypes.string,
  menuZIndex: PropTypes.number,
};

FilterItemValues.defaultProps = {
  defaultOptions: undefined,
  onBlur: noop,
  hideAdd: undefined,
  showAdd: undefined,
  selectedValues: undefined,
  multiple: undefined,
  clearable: undefined,
  uom: undefined,
  warning: undefined,
  error: undefined,
  customValues: undefined,
  search: undefined,
  ignoreFilter: undefined,
  persistCasing: undefined,
  itemsSmall: undefined,
  defaultValue: undefined,
  selectedValue: undefined,
  setSelectedValue: undefined,
  title: undefined,
  optionValues: [],
  size: undefined,
  small: undefined,
  medium: undefined,
  semibold: undefined,
  colorOverride: undefined,
  minDropdownWidth: undefined,
  range: undefined,
  rangeKey: undefined,
  optionEllipsis: undefined,
  popperModifiers: undefined,
  itemSelection: undefined,
  fullBorder: undefined,
  maxHeight: undefined,
  menuZIndex: undefined,
};

export default memo(FilterItemValues, (pp, np) => {
  const errorUpdate = np?.error === pp?.error;
  const selectedValueMatch = np?.selectedValue === pp?.selectedValue;
  const selectedValuesLength =
    lodashSize(np?.selectedValues) === lodashSize(pp?.selectedValues);
  const selectedRangeMatch =
    np?.selectedValue?.[`${np.rangeKey}`]?.value &&
    np?.selectedValue?.[`${np.rangeKey}`]?.value ===
      pp?.selectedValue?.[`${pp.rangeKey}`]?.value;
  const uomMatch = np?.uom === pp?.uom;
  return np.noMemo
    ? false
    : errorUpdate &&
        selectedValuesLength &&
        (!np?.range ? selectedValueMatch : selectedRangeMatch) &&
        uomMatch &&
        lodashSize(np?.optionValues) === lodashSize(pp?.optionValues) &&
        np?.optionValues?.[0]?.value === pp?.optionValues?.[0]?.value &&
        np?.optionValues?.[0]?.value &&
        !np?.search;
});
