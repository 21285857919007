import {
  values,
  find,
  filter,
  isEmpty,
  map,
  get,
  keys,
  merge,
  reduce,
  pick,
  mapValues,
  cloneDeep,
  debounce,
  includes,
  size,
  reject,
  isEqual,
  isFunction,
  unset,
} from 'lodash';
import moment from 'moment';
import tree from 'state';
import * as customerEffects from 'state/customers/effects';
import uuid from 'uuid';
import Promise from 'bluebird';
import asyncTreeRequester from 'utility/asyncTreeRequester';
import * as effects from './effects';

import {
  generateRfqOtherMetaInformation,
  generateItemFilters,
  generateFilterValues,
  toggleRangeSelection,
} from './transformer';
import {
  generateTechnicalDetails,
  minimumDataForTechnicalDetails,
  transformTechnicalDetails,
} from './transformer_technicalDetails';
import { onUpdate } from './onUpdate';
import submitItemsPreparer from './preparer_items';
import {
  validateFilterOptions,
  validateTechnicalDetails,
  setAndGetRowValidation,
  itemRowsValid,
} from './validation_items';

// this allows us to reuse logic for items cursor elsewhere
// we will be deprecating this way of managing items. moving towards a hooks approach.
class CursorManager {
  constructor() {
    this.cursor = tree.select(['requestForQuote']);
  }

  set(path, type, options = {}) {
    this.cursor = tree.select(path);
    this.type = type;
    this.userId = options.userId; // for customers wishlist view only
    this.options = options;
  }

  get() {
    return this.cursor;
  }

  getType() {
    return this.type;
  }

  getUserId() {
    return this.userId;
  }

  getOptions() {
    return this.options;
  }

  getIgnoreTechnicalDetails() {
    return (
      this.type === 'public' ||
      this.type === 'inventory' ||
      this.type === 'onboarding' ||
      this.type === 'customers'
    );
  }
}

const cmInstance = new CursorManager();
export function getCurrentCursor() {
  return cmInstance.get();
}

export function setItemsCursor(c, type, options) {
  cmInstance.set(c, type, options);
}
// end this allows us to reuse logic for items cursor elsewhere

// ACCORDIAN HEADER

export function updateFinishedSize({ id, checked }) {
  const currentCursor = getCurrentCursor();
  currentCursor.set(['itemSelection', id, 'finishedSizeState'], checked);
  currentCursor.set(['itemSelection', id, 'dirty'], true);
  currentCursor.set(['itemSelection', id, 'saved'], false);

  let data = cloneDeep(
    getCurrentCursor().get(['itemSelection', id, 'filters'])
  );
  data = toggleRangeSelection(data, { allowRange: !checked, resetValue: true });
  data.alloy.multiple = !checked;
  data.end_finish.multiple = !checked;
  getCurrentCursor().set(['itemSelection', id, 'filters'], data);

  tree.commit();
}

// TECHNICAL DETAILS & ACCORDIAN BODY
export async function getRfqTechnicalData(
  data,
  path,
  errorPath,
  technicalDetailsConfirmed,
  isMfg
) {
  await asyncTreeRequester({
    func: effects.getRfqTechnicalData.bind(this, data, isMfg),
    cursor: getCurrentCursor(),
    path,
    errorPath,
    metadata: { technicalDetailsConfirmed },
    transformer: ({ result, cursor, metadata }) =>
      transformTechnicalDetails(result, cursor, metadata),
  });
}

const validateTechnicalDetailsDebounced = debounce(
  validateTechnicalDetails,
  400,
  { maxWait: 400 }
);

function setDirtyItem(id, customPath = []) {
  if (getCurrentCursor().get([...customPath, 'itemSelection', id, 'saved'])) {
    getCurrentCursor().merge([...customPath, 'itemSelection', id], {
      dirty: true,
      saved: false,
    });
  }
}

const setDirtyItemDebounced = debounce(setDirtyItem, 400, { maxWait: 400 });

export async function setFilterItemBody(id, type, data) {
  const _data = cloneDeep(data);
  const ignoreTechnicalDetails = cmInstance.getIgnoreTechnicalDetails();
  _data.dirty = true;
  getCurrentCursor().set(
    ['itemSelection', id, 'technical_details', 'result', type],
    _data
  );
  tree.commit();
  validateTechnicalDetailsDebounced(id, getCurrentCursor());

  const itemSelection = getCurrentCursor().get(['itemSelection']);
  setDirtyItemDebounced(id);
  setAndGetRowValidation({
    currentCursor: getCurrentCursor(),
    row: itemSelection[id],
    rowId: id,
    itemSelectionItem: itemSelection,
    mergeErrors: false,
    ignoreTechnicalDetails,
  });
}

export function toggleInterestItemTechnicalDetails(id, value) {
  const ignoreTechnicalDetails = cmInstance.getIgnoreTechnicalDetails();
  if (value === 'skipped') {
    getCurrentCursor().set(
      ['itemSelection', id, 'technicalDetailsConfirmed'],
      'skipped'
    );
  } else {
    getCurrentCursor().set(
      ['itemSelection', id, 'technicalDetailsConfirmed'],
      value
    );
  }
  const itemSelection = getCurrentCursor().get(['itemSelection']);
  setDirtyItemDebounced(id);
  setAndGetRowValidation({
    currentCursor: getCurrentCursor(),
    row: itemSelection[id],
    rowId: id,
    itemSelectionItem: itemSelection,
    mergeErrors: false,
    ignoreTechnicalDetails,
  });
}
// END TECHNICAL DETAILS & ACCORDIAN BODY

// REQUEST HELPERS
export function formatDataForTechnicalDetails(
  id,
  technicalDetailsConfirmed,
  isMfg,
  customPath = []
) {
  const path = [...customPath, 'itemSelection', id];
  const rfqItem = getCurrentCursor().get([...path, 'filters']);
  const { hasFields, items } = minimumDataForTechnicalDetails(rfqItem);

  if (hasFields) {
    const endValues = generateFilterValues(items, isMfg);
    getRfqTechnicalData(
      endValues,
      [...path, 'technical_details'],
      [...path, 'errors'],
      technicalDetailsConfirmed,
      isMfg
    );
  }
}

const formatDataForTechnicalDetailsDebounced = debounce(
  formatDataForTechnicalDetails,
  400,
  { maxWait: 400 }
);
const validateFilterOptionsDebounced = debounce(validateFilterOptions, 400, {
  maxWait: 400,
});

function setAndGetRowValidationForChange(data) {
  setAndGetRowValidation(data);
}

const setAndGetRowValidationForChangeDebounced = debounce(
  setAndGetRowValidationForChange,
  400,
  { maxWait: 400 }
);

function setCategoryTemplate(id, selectedTemplate, customPath, isMfg) {
  const _customPath = customPath || [];
  const mfgFieldsOverride = {
    range: true,
    lengths_exact: false,
  };
  const data = cloneDeep(
    getCurrentCursor().get([..._customPath, 'itemSelection', id, 'filters'])
  );
  const newData = mapValues(data, (_filter, key) => {
    function disabled() {
      if (_filter.optionField) {
        return _filter.disabled;
      }
      if (
        isMfg &&
        Object.prototype.hasOwnProperty.call(mfgFieldsOverride, key)
      ) {
        return mfgFieldsOverride[key];
      }
      if (_filter.templates) {
        return !includes(_filter.templates, selectedTemplate);
      }
      return false;
    }

    return {
      ..._filter,
      disabled: disabled(),
    };
  });
  getCurrentCursor().set(
    [..._customPath, 'itemSelection', id, 'filters'],
    newData
  );
}

export function addInterestItem({
  meta,
  initial,
  seed,
  unshift,
  localSeed,
  hidden,
  disableRange,
  customCriteriaKey,
  customCriteriaConditionFunc,
  allowCriteriaKeyFallback,
  itemFilterOptions,
  customPath = [],
  ignoreTechnicalDetails,
  isMfg,
  unsaved,
}) {
  // seed is data coming from the server.
  const instanceOptions = cmInstance.getOptions();
  const _ignoreTechnicalDetails =
    cmInstance.getIgnoreTechnicalDetails() || ignoreTechnicalDetails;
  if (
    !seed &&
    !itemRowsValid({
      _ignoreTechnicalDetails,
      checkForDirty: true,
      currentCursor: getCurrentCursor(),
    }) &&
    !initial
  ) {
    return null;
  }
  const serverTechnicalDetails =
    seed && seed.id
      ? pick(seed, [
          'technical_od_max',
          'technical_inside_diameter_min',
          'burst_min',
          'collapse_min',
          'drift',
          'tensile_strength_min',
          'lengths_min',
          'lengths_max',
          'pren_min',
        ])
      : null;
  const itemSelectionId = seed && seed.id ? seed.id : uuid.v4();
  let useCustomCriteria = false;
  if (customCriteriaKey && isFunction(customCriteriaConditionFunc)) {
    useCustomCriteria = customCriteriaConditionFunc(seed);
  } else if (customCriteriaKey) {
    useCustomCriteria = true;
  }
  const customSeed = useCustomCriteria ? seed[customCriteriaKey] : null;
  let finalSeed;
  if (customSeed) {
    finalSeed = customSeed;
  } else if (allowCriteriaKeyFallback !== false) {
    finalSeed = seed;
  }
  const endItem = {
    [itemSelectionId]: {
      id: itemSelectionId,
      filters: generateItemFilters(meta, finalSeed, {
        ...instanceOptions,
        ...itemFilterOptions,
        localSeed,
        hidden,
        isCreating: true,
        disableRange,
        lengths_exact: {
          ...(instanceOptions?.lengths_exact || {}),
          ...(itemFilterOptions?.lengths_exact || {}),
          enabled: isMfg,
        },
        range: {
          ...(instanceOptions?.range || {}),
          ...(itemFilterOptions?.range || {}),
          disabled: isMfg,
        },
        isMfg,
      }),
      ...generateRfqOtherMetaInformation(
        meta,
        seed,
        instanceOptions,
        localSeed,
        unsaved
      ),
      ...(serverTechnicalDetails
        ? {
            technical_details: {
              result: transformTechnicalDetails(
                serverTechnicalDetails,
                undefined,
                meta
              ),
            },
          }
        : generateTechnicalDetails(meta)),
      original: seed || localSeed,
    },
  };
  if (finalSeed && finalSeed.id) {
    return endItem;
  }
  if (unshift) {
    getCurrentCursor().unshift(
      [...customPath, 'itemSelectionArray'],
      itemSelectionId
    );
    getCurrentCursor().merge([...customPath, 'itemSelection'], endItem);
  } else if (initial) {
    getCurrentCursor().set(
      [...customPath, 'itemSelectionArray'],
      [itemSelectionId]
    );
    getCurrentCursor().set([...customPath, 'itemSelection'], endItem);
  } else {
    getCurrentCursor().push(
      [...customPath, 'itemSelectionArray'],
      itemSelectionId
    );
    getCurrentCursor().merge([...customPath, 'itemSelection'], endItem);
  }
  tree.commit();
  // If this is an empty MRFQ Item, default to Mechanical Tube
  if (isMfg) {
    setCategoryTemplate(itemSelectionId, 'Mechanical', customPath, isMfg);
  }
  if (localSeed) {
    const currentCursor = getCurrentCursor();
    const itemSelection = currentCursor.get([...customPath, 'itemSelection']);
    const newItem = endItem[itemSelectionId];
    setCategoryTemplate(
      newItem.id,
      newItem.filters.category.value.template,
      null,
      isMfg
    );
    setAndGetRowValidationForChangeDebounced({
      currentCursor,
      row: itemSelection[newItem.id],
      rowId: newItem.id,
      itemSelectionItem: itemSelection,
      mergeErrors: false,
      ignoreTechnicalDetails: _ignoreTechnicalDetails,
    });
  }
  return endItem;
}

// INITIALIZERS + META
export async function initializePublicRequest({
  meta,
  seed,
  localSeed,
  hidden,
} = {}) {
  if (
    !find(values(getCurrentCursor().get(['itemSelection'])), { temp: true })
  ) {
    getCurrentCursor().set(['itemSelectionArray'], []);
    getCurrentCursor().set(['itemSelection'], {});
    addInterestItem({ meta, initial: true, seed, localSeed, hidden });
  }
}

function getFetchFunction({
  id,
  token,
  cipher,
  userId,
  interestItemId,
  isMfg,
}) {
  const isOnboarding = cmInstance.getType() === 'onboarding';
  const isCustomers = cmInstance.getType() === 'customers';
  if (interestItemId && (isCustomers || isOnboarding)) {
    return async () => {
      const items = await customerEffects.getWishlistItem({
        userId,
        interestItemId,
      }); // CURRENTLY CODED TO GET ALL SINCE SINGLE ENDPOINT TO GET ONE ITEM DOES NOT EXIST

      // TEMP UNTIL ENDPOINT FOR ONE ITEM IS DONE
      const itemInList = filter(
        get(items, 'docs'),
        (item) => Number(interestItemId) === Number(item.id)
      );
      return { docs: itemInList };
    };
  }
  if (isCustomers) {
    return customerEffects.getCustomerProductWishlist.bind(this, {
      token,
      cipher,
      userId: cmInstance.getUserId() || userId,
    });
  }
  if (isOnboarding) {
    return effects.getUserInterestItems.bind(this);
  }
  if (id) {
    return effects.getRfqGroupItems.bind(this, id, isMfg);
  }
  return effects.getRfqOrphans.bind(this, { userId }, isMfg);
}

export function populateInterestItems({
  items,
  meta,
  hidden,
  disableRangeFunc,
  customCriteriaKey,
  customCriteriaConditionFunc,
  allowCriteriaKeyFallback,
  isMfg,
  customPath = [],
}) {
  let itemsFormatted = {};
  itemsFormatted = reduce(
    items,
    (accum, item) => {
      const disableRange = isFunction(disableRangeFunc)
        ? disableRangeFunc(item)
        : false;
      const _accum = merge(
        accum,
        addInterestItem({
          meta,
          initial: true,
          seed: item,
          hidden,
          disableRange,
          customCriteriaKey,
          customCriteriaConditionFunc,
          allowCriteriaKeyFallback,
          isMfg,
          customPath,
        })
      );
      return _accum;
    },
    {}
  );

  const newArray = keys(itemsFormatted);
  getCurrentCursor().set([...customPath, 'itemSelectionArray'], newArray); // this is done for performance reasons
  getCurrentCursor().set([...customPath, 'itemSelection'], itemsFormatted);
}

export async function getRfqGroupitems(id, meta, options, isMfg) {
  const {
    userId,
    token,
    cipher,
    localSeed,
    hidden,
    interestItemId,
    customCriteriaKey,
    customCriteriaConditionFunc,
    allowCriteriaKeyFallback,
  } = options || {};
  const items = await asyncTreeRequester({
    func: getFetchFunction({
      id,
      token,
      cipher,
      userId,
      interestItemId,
      isMfg,
    }),
    cursor: getCurrentCursor(),
    path: ['activeRfq', 'items'],
  });

  if (size(get(items, 'docs'))) {
    populateInterestItems({
      items: items.docs,
      metadata: meta,
      hidden,
      customCriteriaKey,
      customCriteriaConditionFunc,
      allowCriteriaKeyFallback,
      isMfg,
    });
    return items;
  }

  addInterestItem({ meta, initial: true, localSeed, hidden, isMfg });
  return [];
}
// END INITIALIZERS + META

// TOP LEVEL FILTER ITEMS
export async function setItemNotes(id, data) {
  const _data = cloneDeep(data);
  _data.dirty = true;
  getCurrentCursor().merge(['itemSelection', id, 'notes'], _data);
  tree.commit();
}

export function allInterestItemsValid() {
  // interestItemsValidMonkey Monkey replacement due to issues with monkey? look into todo
  const itemSelection = getCurrentCursor().get(['itemSelection']);
  if (isEmpty(itemSelection)) {
    return {};
  }
  return reduce(
    values(itemSelection),
    (accum, item) => {
      const _accum = accum;
      if (_accum.allValid && !item.valid) {
        _accum.allValid = false;
      }

      if (_accum.allSaved && !item.saved) {
        _accum.allSaved = false;
      }
      return _accum;
    },
    { allValid: true, allSaved: true }
  );
}

export async function setInterestItem(
  id,
  branch,
  type,
  data,
  { isMfg, customPath = [] } = {}
) {
  const _data = cloneDeep(data);
  const ignoreTechnicalDetails = cmInstance.getIgnoreTechnicalDetails();
  const currentCursor = getCurrentCursor();
  const itemData = currentCursor.get([...customPath, 'itemSelection', id]);
  const currentValue = currentCursor.get([
    ...customPath,
    'itemSelection',
    id,
    branch,
    type,
    'value',
  ]);
  _data.dirty = true;
  await Promise.delay(10);
  currentCursor.set([...customPath, 'itemSelection', id, branch, type], _data);
  tree.commit();
  await Promise.delay(10);

  if (type === 'category' && get(_data, 'value.template')) {
    setCategoryTemplate(id, _data.value.template, customPath, isMfg);
    await Promise.delay(10);
  }
  /* If the user has changed the OD, clear the #/FT selection if the current selection is invalid for the new OD.
    This is true for almost all cases, since each OD has its own set of #/FT options.
    But it's possible for the user to select a #/FT option, then select an OD that it is valid for,
    so we want to preserve that.
  */
  if (type === 'od') {
    const weightPerFootFilter = currentCursor.get([
      ...customPath,
      'itemSelection',
      id,
      branch,
      'weight_per_foot',
    ]);
    // If no #/FT is selected, we don't need to validate
    const hasValidWeightPerFoot = weightPerFootFilter.value
      ? weightPerFootFilter.value.standard_od === data.value.id
      : true;
    if (!hasValidWeightPerFoot) {
      // We must avoid mutating the filter object itself so the rendered dropdown state will update
      const newWeightPerFootFilter = {
        ...weightPerFootFilter,
      };
      unset(newWeightPerFootFilter, 'value');
      currentCursor.set(
        [...customPath, 'itemSelection', id, branch, 'weight_per_foot'],
        newWeightPerFootFilter
      );
    }
  }
  const updatePath = customPath && [
    ...customPath,
    'itemSelection',
    id,
    'filters',
  ];
  onUpdate({ id, type, branch, currentCursor, path: updatePath });
  await Promise.delay(10);

  validateFilterOptionsDebounced(id, currentCursor, customPath);
  await Promise.delay(10);

  const itemSelection = currentCursor.get([...customPath, 'itemSelection']);
  if (!ignoreTechnicalDetails) {
    formatDataForTechnicalDetailsDebounced(
      id,
      itemData.technicalDetailsConfirmed,
      isMfg,
      customPath
    );
  }

  await Promise.delay(10);
  if (!isEqual(data.value, currentValue)) {
    setDirtyItemDebounced(id, customPath);
  }
  setAndGetRowValidationForChangeDebounced({
    currentCursor,
    row: itemSelection[id],
    rowId: id,
    itemSelectionItem: itemSelection,
    mergeErrors: false,
    ignoreTechnicalDetails,
    customPath,
  });
}

export function cloneInterestItem({ item }) {
  const ignoreTechnicalDetails = cmInstance.getIgnoreTechnicalDetails();
  if (
    !itemRowsValid({
      ignoreTechnicalDetails,
      checkForDirty: true,
      currentCursor: getCurrentCursor(),
    })
  ) {
    return null;
  }
  const itemId = uuid.v4();
  getCurrentCursor().merge(['itemSelection'], {
    [itemId]: {
      ...item,
      id: itemId,
      name: `Cloned Item: ${item.name}`,
      temp: true,
      saved: false,
      dirty: true,
    },
  });
  getCurrentCursor().push(['itemSelectionArray'], itemId);

  return itemId;
}

export function validateOnSave({
  itemSelectionId,
  ignoreTechnicalDetails,
  requiredFields,
}) {
  const itemSelectionItem = getCurrentCursor().get(['itemSelection']);
  return setAndGetRowValidation({
    currentCursor: getCurrentCursor(),
    row: itemSelectionItem[itemSelectionId],
    rowId: itemSelectionId,
    itemSelectionItem,
    mergeErrors: true,
    ignoreTechnicalDetails,
    requiredFields,
  });
}

export async function saveInterestItem(
  itemSelectionId,
  { update, userId, wishlist, interceptFunction, requiredFields },
  isMfg
) {
  const instanceOptions = cmInstance.getOptions();
  const ignoreTechnicalDetails = cmInstance.getIgnoreTechnicalDetails();

  const rfqId = getCurrentCursor().get(['id']);
  const errors = validateOnSave({
    itemSelectionId,
    ignoreTechnicalDetails,
    requiredFields,
  });

  if (!errors.length) {
    const newItems = getCurrentCursor().get(['itemSelection']);
    const item = newItems[itemSelectionId];
    const preparedData = submitItemsPreparer(item);
    const notes = item && get(item.notes, 'data');
    const finishedSizeState = item?.finishedSizeState;
    preparedData.notes = notes || null;
    preparedData.finished_size = finishedSizeState;

    const productType = item && get(item.userProductType, 'value.value');
    if (productType) {
      preparedData.type = productType;
    }
    if (userId) {
      preparedData.user = userId;
    }

    const { cipher, token } = instanceOptions || {}; // THIS IS USED FOR WISHLIST LANDING
    const hasCipher = cipher && token;

    if (interceptFunction) {
      // FOR UN AUTHENTICATED REQUESTS WE WILL NEED TO INTERCEPT
      interceptFunction(preparedData, itemSelectionId, { cipher, token });
      return;
    }

    const authUserId = tree.get(['authentication', 'session', 'user_id']);
    const isWishlist = wishlist && (userId || hasCipher || authUserId);

    let func;
    if (isWishlist && !update) {
      func = effects.saveUserInterestItems.bind(this, preparedData, {
        cipher,
        token,
      });
    } else if (isWishlist && update) {
      func = effects.updateUserInterestItems.bind(this, item.id, preparedData, {
        cipher,
        token,
      });
    } else if (!update) {
      func = effects.saveItemData.bind(this, preparedData, {}, isMfg);
    } else {
      func = effects.updateItemData.bind(this, item.id, preparedData, isMfg);
    }

    const result = await asyncTreeRequester({
      func,
      cursor: getCurrentCursor(),
      path: ['itemSelection', itemSelectionId],
      handleResult: true,
      delayedLoading: 1000,
    });

    if (!result || (result && result.error)) {
      getCurrentCursor().merge(['itemSelection', itemSelectionId], {
        error: result.error,
      });
    } else {
      // UPDATE RFQ
      let updateRfqError;
      if (rfqId && !update) {
        const itemSelection = getCurrentCursor().get(['itemSelection']);
        const savedItems = reject(values(itemSelection), 'temp');
        const savedItemIds = map(savedItems, 'id');
        try {
          await effects.updateRfqGroup(
            rfqId,
            {
              rfq_item: isMfg ? undefined : [...savedItemIds, result.id],
              mrfq_item: isMfg ? [...savedItemIds, result.id] : undefined,
            },
            isMfg
          );
          getCurrentCursor().set(['itemSelectionRfqUpdateError'], false);
        } catch (err) {
          updateRfqError = err.message;
          getCurrentCursor().set(['itemSelectionRfqUpdateError'], err.message); // TODO test
        }
      }

      const newItem = {
        id: result.id,
        lastSaved: moment().format(),
        saved: !updateRfqError,
        original: result,
        valid: true,
        dirty: false,
        temp: false,
        error: updateRfqError,
        notes: {
          seed: notes,
          data: notes,
          dirty: false,
        },
      };
      getCurrentCursor().merge(['itemSelection', itemSelectionId], newItem);
      tree.commit();
    }
  }
}

export async function removeInterestItem(
  id,
  temp,
  replaceWithDefaultItem,
  isMfg
) {
  if (
    window.confirm(
      'Are you sure you want to remove this item? It cannot be undone.'
    )
  ) {
    const list = getCurrentCursor().get('itemSelectionArray');
    const isOnboarding = cmInstance.getType() === 'onboarding';
    const isCustomers = cmInstance.getType() === 'customers';
    const instanceOptions = cmInstance.getOptions();
    const { cipher, token } = instanceOptions || {}; // THIS IS USED FOR WISHLIST LANDING

    if (temp) {
      getCurrentCursor().set(
        'itemSelectionArray',
        filter(list, (item) => item !== id)
      );
      getCurrentCursor().unset(['itemSelection', id]);
      return;
    }
    const newItems = getCurrentCursor().get(['itemSelection']);
    const serverId = get(newItems[id], 'id') || id; // we want to get the actual item id not the local reference

    const result = await asyncTreeRequester({
      func:
        isOnboarding || isCustomers
          ? effects.deleteUserInterestItems.bind(this, serverId, {
              cipher,
              token,
            })
          : effects.deleteItemData.bind(this, serverId, isMfg),
      cursor: getCurrentCursor(),
      path: ['itemSelection', id],
      deleting: true,
      handleResult: true,
      delayedLoading: 1000,
    });

    if (!result || (result && result.error)) {
      getCurrentCursor().merge(['itemSelection', id], { error: result.error });
      tree.commit();
    } else {
      getCurrentCursor().set(
        'itemSelectionArray',
        filter(list, (item) => item !== id)
      );
      getCurrentCursor().unset(['itemSelection', id]);
      tree.commit();
      if (replaceWithDefaultItem) {
        // this is when there is one item from orphans and we want to delete it.
        addInterestItem({ initial: true });
      }
    }
  }
}
// END TOP LEVEL FILTER ITEMS
