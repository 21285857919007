import PropTypes, { arrayOf } from 'prop-types';
import { canUseDOM } from 'exenv';

// ========================= HEAVILY RE-USED ===============================

/*
  Spreadable PropTypes definitions
  The linter needs us to assign to an object key in order to check for forbidden PropTypes
  The name of the key (definitions) is arbitrary
*/
const idNameParsable = {};
idNameParsable.definitions = {
  id: PropTypes.number,
  name: PropTypes.string,
};
export const idNameObject = idNameParsable.definitions;

/*
  Aliased PropTypes definitions
  The linter needs us to assign to an object key, then provide an object with another key 
  in order to check for forbidden PropTypes
  The names of both keys (definition, idNameArr) are abritrary, but naming the nested key
  the same as its indended alias allows us to destructure more elegantly
*/
const idNameArrParsable = {};
idNameArrParsable.definition = {
  idNameArr: PropTypes.arrayOf(PropTypes.shape(idNameObject)),
};
export const { idNameArr } = idNameArrParsable.definition;

const strArrParsable = {};
strArrParsable.definition = {
  strArr: PropTypes.arrayOf(PropTypes.string),
};
export const { strArr } = strArrParsable.definition;

const numArrParsable = {};
numArrParsable.definition = {
  numArr: PropTypes.arrayOf(PropTypes.number),
};
export const { numArr } = numArrParsable.definition;

// =========================================================================

export const paginationShape = PropTypes.shape({
  page: PropTypes.number,
  num_pages: PropTypes.number,
  total: PropTypes.number,
  limit: PropTypes.number,
});

export const sortShape = PropTypes.shape({
  direction: PropTypes.string,
  // LINT OVERRIDE #6
  // Object has undetermined keys
  // eslint-disable-next-line react/forbid-prop-types
  sort: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
});

export const facetsShape = PropTypes.shape({
  active: PropTypes.shape({
    // LINT OVERRIDE #6
    // Object has undetermined keys
    // eslint-disable-next-line react/forbid-prop-types
    filters: PropTypes.object,
    pagination: paginationShape,
    sort: sortShape,
  }),
  paths: PropTypes.shape({
    filters: PropTypes.arrayOf(PropTypes.string),
    pagination: PropTypes.arrayOf(PropTypes.string),
    result: PropTypes.arrayOf(PropTypes.string),
    sort: PropTypes.arrayOf(PropTypes.string),
  }),
  omit: PropTypes.arrayOf(PropTypes.string),
});

export const activeRoutesOpportunityTable = PropTypes.shape({
  priority: PropTypes.bool,
  status: PropTypes.bool,
  owner: PropTypes.bool,
  end_user_name: PropTypes.bool,
  inquiring_company_name: PropTypes.bool,
  project_name: PropTypes.bool,
  need_date: PropTypes.bool,
  location: PropTypes.bool,
  role_quote_lead_name: PropTypes.bool,
  role_owner_name: PropTypes.bool,
  engagement_title: PropTypes.bool,
  entry_date: PropTypes.bool,
});

export const opportunityItemShape = PropTypes.shape({
  id: PropTypes.number,
  update_timestamp: PropTypes.string,
  document_type: PropTypes.string,
  is_parent: PropTypes.bool,
  create_timestamp: PropTypes.string,
  timestamp: PropTypes.string,
  _version_: PropTypes.number,
  od: PropTypes.number,
  inside_diameter: PropTypes.number,
  weight_per_foot: PropTypes.number,
  min_yield: PropTypes.number,
  technical_details_confirmed: PropTypes.bool,
  burst_min: PropTypes.number,
  collapse_min: PropTypes.number,
  tensile_strength_min: PropTypes.number,
  pren_min: PropTypes.number,
  drift: PropTypes.number,
  range_id: PropTypes.number,
  quantity: PropTypes.number,
  quantity_uom: PropTypes.string,
  wall: PropTypes.number,
  name: PropTypes.string,
  deleted: PropTypes.bool,
  technical_inside_diameter_min: PropTypes.number,
  technical_od_max: PropTypes.number,
  status: PropTypes.string,
  category: PropTypes.number,
  submitter: PropTypes.number,
  user: PropTypes.number,
  rfq: PropTypes.number,
  category_name: PropTypes.string,
  category_object_prefix: PropTypes.string,
  submitter_name: PropTypes.string,
  user_name: PropTypes.string,
  range: PropTypes.string,
  project_name: PropTypes.string,
  need_date: PropTypes.string,
  need_location_id: PropTypes.number,
  need_location_latitude: PropTypes.number,
  need_location_longitude: PropTypes.number,
  need_location: PropTypes.string,
  need_location_country: PropTypes.string,
  need_location_place_id: PropTypes.string,
  metallurgy: numArr,
  metallurgy_name: strArr,
  implied_metallurgy_group: numArr,
  implied_metallurgy_group_name: strArr,
  end_finish: numArr,
  end_finish_name: strArr,
  rfq_status: PropTypes.string,
  quantity_uom_id: PropTypes.number,
  quantity_display: PropTypes.string,
  footage: PropTypes.number,
  lbs: PropTypes.number,
  rfq_status_display: PropTypes.string,
  status_rank: PropTypes.number,
  id_search: PropTypes.string,
  following: numArr,
});

export const opportunityTableItemsShape = PropTypes.shape({
  numFound: PropTypes.number,
  start: PropTypes.number,
  numFoundExact: PropTypes.bool,
  docs: PropTypes.arrayOf(opportunityItemShape),
  id: PropTypes.number,
  rfq: PropTypes.shape({
    id: PropTypes.number,
    update_timestamp: PropTypes.string,
    document_type: PropTypes.string,
    is_parent: PropTypes.bool,
    create_timestamp: PropTypes.string,
    timestamp: PropTypes.string,
    _version_: PropTypes.number,
    need_date: PropTypes.string,
    project_name: PropTypes.string,
    reference_number: PropTypes.string,
    need_location_id: PropTypes.number,
    status: PropTypes.string,
    user: PropTypes.number,
    submitter_user: PropTypes.number,
    need_location_latitude: PropTypes.number,
    need_location_longitude: PropTypes.number,
    need_location: PropTypes.string,
    need_location_place_id: PropTypes.string,
    need_location_country: PropTypes.string,
    submitter_name: PropTypes.string,
    user_name: PropTypes.string,
    status_display: PropTypes.string,
    status_rank: PropTypes.number,
    footage: PropTypes.number,
    id_search: PropTypes.string,
    following: numArr,
  }),
});

export const userShape = PropTypes.shape({
  contact_status: PropTypes.number,
  create_timestamp: PropTypes.string,
  date_joined: PropTypes.string,
  domain: PropTypes.string,
  email: PropTypes.string,
  first_name: PropTypes.string,
  icon: PropTypes.string,
  id: PropTypes.number,
  is_active: PropTypes.bool,
  last_login: PropTypes.string,
  last_name: PropTypes.string,
  linkedin_checked: PropTypes.bool,
  linkedin_description: PropTypes.string,
  linkedin_title: PropTypes.string,
  linkedin_url: PropTypes.string,
  merged_to: PropTypes.number,
  mobile_phone: PropTypes.string,
  priority: PropTypes.number,
  tags: PropTypes.arrayOf(PropTypes.number),
  update_timestamp: PropTypes.string,
  work_phone: PropTypes.string,
});

export const supplyTableItemsShape = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  update_timestamp: PropTypes.string,
  document_type: PropTypes.string,
  is_parent: PropTypes.bool,
  create_timestamp: PropTypes.string,
  timestamp: PropTypes.string,
  _version_: PropTypes.number,
  od: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  inside_diameter: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  weight_per_foot: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  wall: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  min_yield: PropTypes.number,
  collapse_strength: PropTypes.number,
  body_yield_strength: PropTypes.number,
  api_internal_pressure: PropTypes.number,
  drift: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  active_status: PropTypes.number,
  manufacture_year: PropTypes.number,
  thread_configuration: PropTypes.number,
  name: PropTypes.string,
  price_uom: PropTypes.number,
  quantity_uom: PropTypes.number,
  quantity: PropTypes.number,
  client_id: PropTypes.number,
  condition_id: PropTypes.number,
  manufacturer_id: PropTypes.number,
  manufacturing_process_id: PropTypes.number,
  range_id: PropTypes.number,
  supply_location_id: PropTypes.number,
  user_id: PropTypes.number,
  status: PropTypes.string,
  yard_name: PropTypes.string,
  category: PropTypes.number,
  submitter: PropTypes.number,
  user: PropTypes.oneOfType([PropTypes.number, userShape]),
  supply_opportunity: PropTypes.number,
  supply_opportunity_status: PropTypes.string,
  supply_opportunity_name: PropTypes.string,
  category_name: PropTypes.string,
  category_object_prefix: PropTypes.string,
  submitter_name: PropTypes.string,
  user_name: PropTypes.string,
  range: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  need_location_latitude: PropTypes.number,
  supply_location_longitude: PropTypes.number,
  supply_location: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  supply_location_country: PropTypes.string,
  supply_location_place_id: PropTypes.string,
  metallurgy: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.number,
  ]),
  metallurgy_name: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  metallurgy_group: PropTypes.arrayOf(PropTypes.number),
  metallurgy_group_name: PropTypes.arrayOf(PropTypes.string),
  end_finish: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.number,
  ]),
  end_finish_name: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  end_finish_licensee: PropTypes.arrayOf(PropTypes.number),
  end_finish_licensee_name: PropTypes.arrayOf(PropTypes.string),
  manufacturer_name: PropTypes.string,
  condition_name: PropTypes.string,
  manufacturing_process_name: PropTypes.string,
  supply_opportunity_status_name: PropTypes.string,
  supply_opportunity_status_rank: PropTypes.number,
  status_name: PropTypes.string,
  status_rank: PropTypes.number,
  type: PropTypes.string,
  type_status: PropTypes.string,
  price_uom_name: PropTypes.string,
  thread_configuration_name: PropTypes.string,
  quantity_uom_name: PropTypes.string,
  quantity_display: PropTypes.string,
});

export const activeRoutesSupplyGroupTable = PropTypes.shape({
  name: PropTypes.bool,
  priority: PropTypes.bool,
  status_name: PropTypes.bool,
  company_contact_name: PropTypes.bool,
  owner: PropTypes.bool,
  footage: PropTypes.bool,
  itemCount: PropTypes.bool,
  created_at: PropTypes.bool,
  engagement_title: PropTypes.bool,
});

export const supplyGroupTableItemsShape = PropTypes.shape({
  numFound: PropTypes.number,
  start: PropTypes.number,
  numFoundExact: PropTypes.bool,
  docs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      update_timestamp: PropTypes.string,
      document_type: PropTypes.string,
      is_parent: PropTypes.bool,
      create_timestamp: PropTypes.string,
      timestamp: PropTypes.string,
      _version_: PropTypes.number,
      od: PropTypes.number,
      inside_diameter: PropTypes.number,
      weight_per_foot: PropTypes.number,
      wall: PropTypes.number,
      min_yield: PropTypes.number,
      collapse_strength: PropTypes.number,
      body_yield_strength: PropTypes.number,
      api_internal_pressure: PropTypes.number,
      drift: PropTypes.number,
      active_status: PropTypes.number,
      manufacture_year: PropTypes.number,
      thread_configuration: PropTypes.number,
      name: PropTypes.string,
      price_uom: PropTypes.number,
      quantity_uom: PropTypes.number,
      quantity: PropTypes.number,
      client_id: PropTypes.number,
      condition_id: PropTypes.number,
      manufacturer_id: PropTypes.number,
      manufacturing_process_id: PropTypes.number,
      range_id: PropTypes.number,
      supply_location_id: PropTypes.number,
      user_id: PropTypes.number,
      status: PropTypes.string,
      yard_name: PropTypes.string,
      category: PropTypes.number,
      submitter: PropTypes.number,
      user: PropTypes.number,
      supply_opportunity: PropTypes.number,
      supply_opportunity_status: PropTypes.string,
      supply_opportunity_name: PropTypes.string,
      category_name: PropTypes.string,
      category_object_prefix: PropTypes.string,
      submitter_name: PropTypes.string,
      user_name: PropTypes.string,
      range: PropTypes.string,
      need_location_latitude: PropTypes.number,
      supply_location_longitude: PropTypes.number,
      supply_location: PropTypes.string,
      supply_location_country: PropTypes.string,
      supply_location_place_id: PropTypes.string,
      metallurgy: numArr,
      metallurgy_name: strArr,
      metallurgy_group: numArr,
      metallurgy_group_name: strArr,
      end_finish: numArr,
      end_finish_name: strArr,
      end_finish_licensee: numArr,
      end_finish_licensee_name: strArr,
      manufacturer_name: PropTypes.string,
      condition_name: PropTypes.string,
      manufacturing_process_name: PropTypes.string,
      supply_opportunity_status_name: PropTypes.string,
      supply_opportunity_status_rank: PropTypes.number,
      status_name: PropTypes.string,
      status_rank: PropTypes.number,
      type: PropTypes.string,
      type_status: PropTypes.string,
      price_uom_name: PropTypes.string,
      thread_configuration_name: PropTypes.string,
      quantity_uom_name: PropTypes.string,
      quantity_display: PropTypes.string,
    })
  ),
  id: PropTypes.number,
  supply_opportunity: PropTypes.shape({
    id: PropTypes.number,
    update_timestamp: PropTypes.string,
    document_type: PropTypes.string,
    is_parent: PropTypes.bool,
    create_timestamp: PropTypes.string,
    timestamp: PropTypes.string,
    _version_: PropTypes.number,
    active_status: PropTypes.number,
    status: PropTypes.string,
    description: PropTypes.string,
    name: PropTypes.string,
    user: PropTypes.number,
    user_name: PropTypes.string,
    following: numArr,
    internal_personnel: strArr,
    status_name: PropTypes.string,
    status_rank: PropTypes.number,
    footage: PropTypes.number,
  }),
});

export const engagementItemsShape = PropTypes.shape({
  id: PropTypes.number,
  update_timestamp: PropTypes.string,
  document_type: PropTypes.string,
  is_parent: PropTypes.bool,
  create_timestamp: PropTypes.string,
  timestamp: PropTypes.string,
  _version_: PropTypes.number,
  active_status: PropTypes.number,
  priority: PropTypes.number,
  title: PropTypes.string,
  uuid: PropTypes.string,
  client_id: PropTypes.number,
  created_by_id: PropTypes.number,
  created_by_name: PropTypes.string,
  json: PropTypes.shape({
    draft: PropTypes.shape({
      blocks: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string,
          data: PropTypes.shape({}),
          text: PropTypes.string,
          type: PropTypes.string,
          depth: PropTypes.number,
          entityRanges: PropTypes.arrayOf(PropTypes.shape({})),
          inlineStyleRanges: PropTypes.arrayOf(PropTypes.shape({})),
        })
      ),
      entityMap: PropTypes.shape({}),
    }),
  }),
  personnel: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      role: PropTypes.number,
      user: PropTypes.number,
      object_id: PropTypes.number,
      role_name: PropTypes.string,
      user_name: PropTypes.string,
      content_type: PropTypes.number,
      create_timestamp: PropTypes.string,
      update_timestamp: PropTypes.string,
      is_personnel_internal: PropTypes.bool,
    })
  ),
  engagement_companies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      company_id: PropTypes.number,
    })
  ),
  engagement_company_names: PropTypes.arrayOf(PropTypes.string),
  priority_name: PropTypes.string,
  active_status_name: PropTypes.string,
});

export const personnelRoleShape = PropTypes.oneOfType([
  PropTypes.shape({
    description: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
    slug: PropTypes.string,
  }),
]);

export const actionItemsItemsShape = PropTypes.shape({
  id: PropTypes.number,
  update_timestamp: PropTypes.string,
  document_type: PropTypes.string,
  is_parent: PropTypes.bool,
  create_timestamp: PropTypes.string,
  timestamp: PropTypes.string,
  _version_: PropTypes.number,
  active_status: PropTypes.number,
  title: PropTypes.string,
  deadline: PropTypes.string,
  priority: PropTypes.number,
  object_id: PropTypes.number,
  uuid: PropTypes.string,
  assignee_id: PropTypes.number,
  client_id: PropTypes.number,
  content_type_id: PropTypes.number,
  creator_id: PropTypes.number,
  actionitem_status: PropTypes.number,
  assignee_name: PropTypes.string,
  creator_name: PropTypes.string,
  parent_name: PropTypes.string,
  priority_name: PropTypes.string,
  json: PropTypes.shape({
    draft: PropTypes.shape({
      blocks: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string,
          data: PropTypes.shape({}),
          text: PropTypes.string,
          type: PropTypes.string,
          depth: PropTypes.number,
          entityRanges: PropTypes.arrayOf(PropTypes.string),
          inlineStyleRanges: PropTypes.arrayOf(
            PropTypes.shape({
              style: PropTypes.string,
              length: PropTypes.number,
              offset: PropTypes.number,
            })
          ),
        })
      ),
      entityMap: PropTypes.shape({}),
    }),
  }),
  active_status_name: PropTypes.string,
  actionitem_status_name: PropTypes.string,
});

export const rfqMetaShape = PropTypes.shape({
  category: PropTypes.arrayOf(
    PropTypes.shape({
      ...idNameObject,
      object_prefix: PropTypes.string,
    })
  ),
  metallurgy: PropTypes.arrayOf(
    PropTypes.shape({
      ...idNameObject,
      metallurgy_group: PropTypes.number,
      slug: PropTypes.string,
      rank: PropTypes.number,
      density: PropTypes.string,
    })
  ),
  metallurgy_group: PropTypes.arrayOf(
    PropTypes.shape({
      ...idNameObject,
      description: PropTypes.string,
      name_long: PropTypes.string,
      slug: PropTypes.string,
    })
  ),
  end_finish: PropTypes.arrayOf(
    PropTypes.shape({
      ...idNameObject,
      licensee: PropTypes.number,
      thread_type: PropTypes.number,
      category: PropTypes.number,
    })
  ),
  end_finish_licensee: PropTypes.arrayOf(
    PropTypes.shape({
      ...idNameObject,
      slug: PropTypes.string,
    })
  ),
  manufacturer: idNameArr,
  end_finish_category: idNameArr,
  end_finish_thread_type: idNameArr,
  weight_uom: idNameArr,
  length_uom: idNameArr,
  pressure_uom: idNameArr,
  temperature_uom: idNameArr,
  chemical_content_uom: idNameArr,
  thread_config: idNameArr,
  manufacturing_process: idNameArr,
  standard_od: PropTypes.arrayOf(
    PropTypes.shape({
      ...idNameObject,
      od: PropTypes.string,
      alternative_name: PropTypes.string,
    })
  ),
  standard_weight: arrayOf(
    PropTypes.shape({
      ...idNameObject,
      weight_per_foot: PropTypes.string,
      wall: PropTypes.string,
      standard_od: PropTypes.number,
    })
  ),
  range: PropTypes.arrayOf(
    PropTypes.shape({
      ...idNameObject,
      category: PropTypes.number,
    })
  ),
  brand: PropTypes.arrayOf(
    PropTypes.shape({
      ...idNameObject,
      brand: PropTypes.string,
      name_long: PropTypes.string,
      origin: PropTypes.string,
      description: PropTypes.string,
      external_brand: PropTypes.bool,
    })
  ),
  condition: idNameArr,
  packaging: idNameArr,
  delivery_type: PropTypes.arrayOf(
    PropTypes.shape({
      ...idNameObject,
      name_long: PropTypes.string,
      description: PropTypes.string,
    })
  ),
  well_type: PropTypes.arrayOf(
    PropTypes.shape({
      ...idNameObject,
      type: PropTypes.number,
      type_display: PropTypes.string,
    })
  ),
  application_type: idNameArr,
  file_content_types: PropTypes.shape({
    RFQWell: PropTypes.number,
    ListingPage: PropTypes.number,
    RFQ: PropTypes.number,
    SupplyOpportunity: PropTypes.number,
    SupplyOpportunityItem: PropTypes.number,
    Metallurgy: PropTypes.number,
    RFQFacilitatorQuotePDF: PropTypes.number,
    Conversation: PropTypes.number,
    SimplifiedInquiry: PropTypes.number,
    SimplifiedSupplyOpportunity: PropTypes.number,
    Company: PropTypes.number,
    Order: PropTypes.number,
  }),
  mtr_content_types: PropTypes.shape({
    SupplyOpportunityItem: PropTypes.number,
  }),
  conversation_content_types: PropTypes.shape({
    SupplyOpportunity: PropTypes.number,
    SupplyOpportunityItem: PropTypes.number,
    RFQ: PropTypes.number,
    Company: PropTypes.number,
    AuthUser: PropTypes.number,
    Campaign: PropTypes.number,
    Engagement: PropTypes.number,
    ListingPage: PropTypes.number,
  }),
  attribute_content_types: PropTypes.shape({
    RFQ: PropTypes.number,
    RFQItem: PropTypes.number,
    RFQWell: PropTypes.number,
    Company: PropTypes.number,
    SupplyOpportunity: PropTypes.number,
    SupplyOpportunityItem: PropTypes.number,
    UserProduct: PropTypes.number,
    AuthUser: PropTypes.number,
    Campaign: PropTypes.number,
    Engagement: PropTypes.number,
    ListingPage: PropTypes.number,
  }),
  entity_schema_content_types: PropTypes.shape({
    Contact: PropTypes.number,
  }),
  priority: idNameArr,
  quantity_uom: idNameArr,
  timing_uom: idNameArr,
  rfq_attribute: PropTypes.arrayOf(
    PropTypes.shape({
      ...idNameObject,
      slug: PropTypes.string,
      description: PropTypes.string,
      tier_1: PropTypes.string,
      tier_2: PropTypes.string,
      tier_3: PropTypes.string,
    })
  ),
  rfq_personnel_role: PropTypes.arrayOf(personnelRoleShape),
  tag_content_types: PropTypes.shape({
    AuthUser: PropTypes.number,
    Company: PropTypes.number,
  }),
  interaction_type: idNameArr,
  crm_content_types: PropTypes.shape({
    engagement: PropTypes.number,
    interaction: PropTypes.number,
    actionitem: PropTypes.number,
  }),
  interaction_content_types: PropTypes.shape({
    Engagement: PropTypes.number,
  }),
  actionitem_content_types: PropTypes.shape({
    Engagement: PropTypes.number,
    AuthUser: PropTypes.number,
  }),
  order_content_types: PropTypes.shape({
    RFQ: PropTypes.number,
    SupplyOpportunity: PropTypes.number,
  }),
  order_item_content_types: PropTypes.shape({
    RFQQuoteItem: PropTypes.number,
    SupplyOpportunityItem: PropTypes.number,
  }),
  supply_opportunity_outcomes: idNameArr,
  object_personnel_attributes: PropTypes.shape({
    'supply opportunity': idNameArr,
    interaction: idNameArr,
    engagement: idNameArr,
  }),
  actionitem_statuses: idNameArr,
});

export const interactionTableItemsShape = PropTypes.shape({
  id: PropTypes.number,
  update_timestamp: PropTypes.string,
  document_type: PropTypes.string,
  is_parent: PropTypes.bool,
  create_timestamp: PropTypes.string,
  timestamp: PropTypes.string,
  _version_: PropTypes.number,
  active_status: PropTypes.number,
  title: PropTypes.string,
  type: PropTypes.number,
  uuid: PropTypes.string,
  object_id: PropTypes.number,
  client_id: PropTypes.number,
  content_type_id: PropTypes.number,
  created_by_id: PropTypes.number,
  date: PropTypes.string,
  type_name: PropTypes.string,
  json: PropTypes.shape({
    draft: PropTypes.shape({
      blocks: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string,
          data: PropTypes.shape({}),
          text: PropTypes.string,
          type: PropTypes.string,
          depth: PropTypes.number,
          entityRanges: PropTypes.arrayOf(PropTypes.shape({})),
          inlineStyleRanges: PropTypes.arrayOf(PropTypes.shape({})),
        })
      ),
      entityMap: PropTypes.shape({}),
    }),
  }),
  personnel: PropTypes.arrayOf(PropTypes.shape({})),
  active_status_name: PropTypes.string,
});

export const historyShape = PropTypes.shape({
  length: PropTypes.number,
  action: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    key: PropTypes.string,
    query: PropTypes.shape({}),
    previous: PropTypes.shape({
      origin: PropTypes.string,
      protocol: PropTypes.string,
      host: PropTypes.string,
      hostname: PropTypes.string,
      port: PropTypes.string,
      pathname: PropTypes.string,
      search: PropTypes.string,
      hash: PropTypes.string,
      href: PropTypes.string,
    }),
  }),
  appPrevious: PropTypes.shape({
    origin: PropTypes.string,
    protocol: PropTypes.string,
    host: PropTypes.string,
    hostname: PropTypes.string,
    port: PropTypes.string,
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    href: PropTypes.string,
  }),
  historyList: PropTypes.arrayOf(
    PropTypes.shape({
      route: PropTypes.string,
      search: PropTypes.string,
    })
  ),
});

export const locationShape = PropTypes.shape({
  pathname: PropTypes.string,
  search: PropTypes.string,
  hash: PropTypes.string,
  state: PropTypes.shape({}),
  key: PropTypes.string,
  query: PropTypes.shape({}),
  previous: PropTypes.shape({
    origin: PropTypes.string,
    protocol: PropTypes.string,
    host: PropTypes.string,
    hostname: PropTypes.string,
    port: PropTypes.string,
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    href: PropTypes.string,
  }),
});

export const routeShape = PropTypes.shape({
  path: PropTypes.oneOfType([strArr, PropTypes.string]),
  exact: PropTypes.bool,
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      path: strArr,
      exact: PropTypes.bool,
    })
  ),
});

export const matchShape = PropTypes.shape({
  path: PropTypes.string,
  url: PropTypes.string,
  isExact: PropTypes.bool,
  params: PropTypes.shape({
    id: PropTypes.string,
  }),
});

export const editorStateShape = PropTypes.shape({
  _immutable: PropTypes.shape({
    allowUndo: PropTypes.bool,
    currentContent: PropTypes.shape({
      entityMap: PropTypes.shape({}),
      blockMap: PropTypes.shape({
        '8lm4o': PropTypes.shape({
          key: PropTypes.string,
          type: PropTypes.string,
          text: PropTypes.string,
          characterList: PropTypes.arrayOf(
            PropTypes.shape({
              style: PropTypes.arrayOf(PropTypes.shape({})),
              entity: PropTypes.shape({}),
            })
          ),
          depth: PropTypes.number,
          data: PropTypes.shape({}),
        }),
      }),
      selectionBefore: PropTypes.shape({
        anchorKey: PropTypes.string,
        anchorOffset: PropTypes.number,
        focusKey: PropTypes.string,
        focusOffset: PropTypes.number,
        isBackward: PropTypes.bool,
        hasFocus: PropTypes.bool,
      }),
      selectionAfter: PropTypes.shape({
        anchorKey: PropTypes.string,
        anchorOffset: PropTypes.number,
        focusKey: PropTypes.string,
        focusOffset: PropTypes.number,
        isBackward: PropTypes.bool,
        hasFocus: PropTypes.bool,
      }),
    }),
    decorator: PropTypes.shape({}),
    directionMap: PropTypes.shape({
      '8lm4o': PropTypes.string,
    }),
    forceSelection: PropTypes.bool,
    inCompositionMode: PropTypes.bool,
    inlineStyleOverride: PropTypes.shape({}),
    lastChangeType: PropTypes.oneOfType([
      PropTypes.shape({}),
      PropTypes.string,
    ]),
    nativelyRenderedContent: PropTypes.shape({}),
    redoStack: PropTypes.shape({}),
    selection: PropTypes.shape({
      anchorKey: PropTypes.string,
      anchorOffset: PropTypes.number,
      focusKey: PropTypes.string,
      focusOffset: PropTypes.number,
      isBackward: PropTypes.bool,
      hasFocus: PropTypes.bool,
    }),
    treeMap: PropTypes.shape({
      '8lm4o': PropTypes.arrayOf(
        PropTypes.shape({
          start: PropTypes.number,
          end: PropTypes.number,
          decoratorKey: PropTypes.shape({}),
          leaves: [
            {
              start: PropTypes.number,
              end: PropTypes.number,
            },
          ],
        })
      ),
    }),
    undoStack: PropTypes.shape({}),
  }),
});

export const actionItemFormDataShape = PropTypes.shape({
  id: PropTypes.number,
  update_timestamp: PropTypes.string,
  document_type: PropTypes.string,
  is_parent: PropTypes.bool,
  create_timestamp: PropTypes.string,
  timestamp: PropTypes.string,
  _version_: PropTypes.number,
  active_status: PropTypes.number,
  title: PropTypes.string,
  deadline: PropTypes.string,
  priority: PropTypes.number,
  uuid: PropTypes.string,
  client_id: PropTypes.number,
  creator_id: PropTypes.number,
  assignee_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  actionitem_status: PropTypes.number,
  assignee_name: PropTypes.string,
  creator_name: PropTypes.string,
  priority_name: PropTypes.string,
  json: PropTypes.shape({
    draft: PropTypes.shape({
      blocks: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string,
          data: PropTypes.shape({}),
          text: PropTypes.string,
          type: PropTypes.string,
          depth: PropTypes.number,
          entityRanges: PropTypes.arrayOf(PropTypes.shape({})),
          inlineStyleRanges: PropTypes.arrayOf(PropTypes.shape({})),
        })
      ),
      entityMap: PropTypes.shape({}),
    }),
  }),
  active_status_name: PropTypes.string,
  actionitem_status_name: PropTypes.string,
});

export const listingMaterialDataShape = PropTypes.shape({
  created: PropTypes.string,
  update: PropTypes.string,
  uuid: PropTypes.string,
  joint_id: PropTypes.string,
  joint: PropTypes.number,
  orientation: PropTypes.string,
  mfg_no: PropTypes.string,
  heat_no: PropTypes.string,
  length: PropTypes.number,
  lot_no: PropTypes.string,
  tube_no: PropTypes.string,
  mtr_no: PropTypes.string,
  stencil_type: PropTypes.string,
  stencil_condition: PropTypes.string,
  etching: PropTypes.string,
  etching_confirmation: PropTypes.string,
  paint_bands: PropTypes.string,
  joint_specific_customer_markings: PropTypes.string,
  od_finish: PropTypes.string,
  od_surface_cond: PropTypes.string,
  rust_level: PropTypes.string,
  remediation_type: PropTypes.string,
  pitting: PropTypes.string,
  tong_marks: PropTypes.string,
  anything_connected: PropTypes.string,
  if_yes_what: PropTypes.string,
  how_connected: PropTypes.string,
  location_connected: PropTypes.string,
  cplg_no: PropTypes.string,
  heat: PropTypes.number,
  lot: PropTypes.number,
  tube: PropTypes.number,
  coupling_mtr_no: PropTypes.string,
  customer_markings_on_coupling: PropTypes.string,
  lot_tong_marks: PropTypes.string,
  coupling_condition: PropTypes.string,
  loose_coupling_buck_unbuck: PropTypes.string,
  coupling_comments: PropTypes.string,
  straightness_p_f: PropTypes.string,
  straightness_max_deviation: PropTypes.string,
  norm_reading: PropTypes.number,
  status: PropTypes.string,
  thread_coating_type: PropTypes.string,
  unit_price: PropTypes.number,
  cost_per_ft: PropTypes.number,
  pipe_body_condition: PropTypes.string,
  location_specific_comment: PropTypes.string,
  rack: PropTypes.string,
  rack_position: PropTypes.string,
  straightness_comments: PropTypes.string,
  pipe_body_comment: PropTypes.string,
  od_mic_end_1_0_deg: PropTypes.number,
  od_mic_end_1_90_deg: PropTypes.number,
  id_mic_end_1: PropTypes.number,
  wall_end_1_0: PropTypes.number,
  wall_end_1_90: PropTypes.number,
  wall_end_1_180: PropTypes.number,
  wall_end_1_270: PropTypes.number,
  od_mic_end_2_0_deg: PropTypes.number,
  od_mic_end_2_90_deg: PropTypes.number,
  id_mic_end_2: PropTypes.number,
  wall_end_2_0: PropTypes.number,
  wall_end_2_90: PropTypes.number,
  wall_end_2_180: PropTypes.number,
  wall_end_2_270: PropTypes.number,
  pipe_body_id_surface: PropTypes.string,
  coupling_od_mic: PropTypes.number,
  vti_box_internal: PropTypes.number,
  vti_pin_internal: PropTypes.number,
  drift_standard: PropTypes.number,
  drift_alt: PropTypes.number,
  drift_2nd_alt: PropTypes.number,
  drift_comments: PropTypes.string,
  pmi: PropTypes.number,
  scrap_value_per_lbs: PropTypes.number,
  scrap_value_total: PropTypes.number,
  item_uuid: PropTypes.string,
  item_name: PropTypes.string,
  yard_uuid: PropTypes.string,
  yard_name: PropTypes.string,
  ownership: PropTypes.number,
  owner_company: PropTypes.string,
  owner_company_id: PropTypes.number,
  pipe_od: PropTypes.number,
  pipe_wall: PropTypes.number,
  pipe_wt_ft: PropTypes.number,
  pipe_id: PropTypes.number,
  pipe_alloy: PropTypes.string,
  pipe_ys: PropTypes.string,
  pipe_end_finish: PropTypes.string,
  pipe_range_length: PropTypes.string,
  category: PropTypes.string,
  lot_id: PropTypes.string,
  lot_uuid: PropTypes.string,
  mfg_id: PropTypes.number,
  mtr_uuid: PropTypes.string,
  metaltrax_uuid: PropTypes.string,
  cost_per_kg: PropTypes.number,
  cost_per_ton: PropTypes.number,
  cost_per_tonne: PropTypes.number,
  cost_per_lbs: PropTypes.number,
  cost_per_in: PropTypes.number,
  cost_per_m: PropTypes.number,
  alloy_group: PropTypes.string,
  acquisition_status: PropTypes.string,
});

export const facetShape = PropTypes.shape({
  buckets: PropTypes.arrayOf(
    PropTypes.shape({
      val: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      count: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  missing: PropTypes.shape({
    count: PropTypes.number,
  }),
});

export const customerShape = PropTypes.shape({
  account_type: PropTypes.number,
  account_type_name: PropTypes.string,
  administrative_area_level_1: PropTypes.string,
  administrative_area_level_2: PropTypes.string,
  attribute_values: PropTypes.arrayOf(PropTypes.number),
  autocomplete_character_count: PropTypes.number,
  client_id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number),
  ]),
  company: PropTypes.number,
  company_name: PropTypes.string,
  company_type: PropTypes.arrayOf(PropTypes.number),
  company_type_name: PropTypes.arrayOf(PropTypes.string),
  contact_id: PropTypes.number,
  contact_status: PropTypes.number,
  contact_status_name: PropTypes.string,
  country: PropTypes.string,
  country_short: PropTypes.string,
  create_timestamp: PropTypes.string,
  date_joined: PropTypes.string,
  document_type: PropTypes.string,
  email: PropTypes.string,
  first_name: PropTypes.string,
  following: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.bool,
  ]),
  formatted_address: PropTypes.string,
  full_name: PropTypes.string,
  google_address_id: PropTypes.number,
  has_user_been_invited: PropTypes.bool,
  id: PropTypes.number,
  is_active: PropTypes.bool,
  is_facilitator: PropTypes.bool,
  is_parent: PropTypes.bool,
  is_pending_moderation: PropTypes.bool,
  is_staff: PropTypes.bool,
  is_superuser: PropTypes.bool,
  job_function: PropTypes.arrayOf(PropTypes.number),
  job_function_name: PropTypes.arrayOf(PropTypes.string),
  job_title: PropTypes.string,
  last_name: PropTypes.string,
  latest_campaign_event: PropTypes.shape({
    campaign_id: PropTypes.number,
    campaign_name: PropTypes.string,
    create_timestamp: PropTypes.string,
    document_type: PropTypes.string,
    event_type: PropTypes.string,
    id: PropTypes.string,
    payload: PropTypes.string,
    timestamp: PropTypes.string,
    user_id: PropTypes.number,
    _version_: PropTypes.number,
  }),
  linkedin_checked: PropTypes.bool,
  locality: PropTypes.string,
  location: PropTypes.string,
  match_type: PropTypes.string,
  mobile_phone: PropTypes.string,
  priority: PropTypes.number,
  priority_name: PropTypes.string,
  priority_rank: PropTypes.number,
  status: PropTypes.number,
  status_name: PropTypes.string,
  timestamp: PropTypes.string,
  update_timestamp: PropTypes.string,
  username: PropTypes.string,
  _version_: PropTypes.number,
});

export const campaignCustomersTableFacets = PropTypes.shape({
  account_type: facetShape,
  attribute_values: facetShape,
  company: facetShape,
  company_type: facetShape,
  job_function: facetShape,
  status: facetShape,
  tag: facetShape,
  type: facetShape,
  count: PropTypes.number,
});

export const attributeShape = PropTypes.shape({
  active_status: PropTypes.number,
  category: PropTypes.number,
  category_name: PropTypes.string,
  client: PropTypes.number,
  id: PropTypes.number,
  sub_category: PropTypes.number,
  value: PropTypes.string,
  value_id: PropTypes.number,
});

export const criteriaShape = PropTypes.shape({
  api_internal_pressure: PropTypes.number,
  body_yield_strength: PropTypes.number,
  category: PropTypes.number,
  category_name: PropTypes.string,
  category_object_prefix: PropTypes.string,
  collapse_strength: PropTypes.number,
  drift: PropTypes.number,
  end_finish: PropTypes.number,
  end_finish_name: PropTypes.string,
  inside_diameter: PropTypes.number,
  metallurgy: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number),
  ]),
  metallurgy_name: PropTypes.string,
  min_pren: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  min_yield: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  od: PropTypes.number,
  range: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  range_name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  wall: PropTypes.number,
  weight_per_foot: PropTypes.number,
});

export const orderItemShape = PropTypes.shape({
  active_status: PropTypes.number,
  brand: PropTypes.number,
  condition: PropTypes.number,
  content_type: PropTypes.number,
  create_timestamp: PropTypes.string,
  criteria: criteriaShape,
  delivery_location: PropTypes.number,
  delivery_location_address: PropTypes.string,
  delivery_location_place_id: PropTypes.string,
  delivery_type: PropTypes.number,
  down_payment: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  final_date: PropTypes.string,
  id: PropTypes.number,
  material_readiness_down_payment: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  mill_delivery: PropTypes.bool,
  net: PropTypes.number,
  notes_draft: PropTypes.string,
  object_id: PropTypes.number,
  order: PropTypes.number,
  order_outcome: PropTypes.number,
  price: PropTypes.string,
  price_uom: PropTypes.number,
  quantity: PropTypes.string,
  quantity_uom: PropTypes.number,
  time_uom: PropTypes.number,
  timing: PropTypes.number,
  timing_max: PropTypes.number,
  timing_min: PropTypes.number,
  total_price: PropTypes.number,
  update_timestamp: PropTypes.string,
  when_timing_starts: PropTypes.string,
});

export const transformerFieldShape = PropTypes.shape({
  item: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number])
  ),
  items: PropTypes.arrayOf(PropTypes.shape({})),
  readOnly: PropTypes.bool,
  serverName: PropTypes.string,
  location: PropTypes.bool,
  addressPath: PropTypes.string,
  required: PropTypes.bool,
  ui: PropTypes.shape({
    condensed: PropTypes.bool,
    extraPadding: PropTypes.string,
    marginBottom: PropTypes.string,
    md: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    noTitle: PropTypes.bool,
    sm: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    lg: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    xl: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    title: PropTypes.string,
  }),
  valid: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  validation: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.shape({
      label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]),
      place_id: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]),
    }),
    PropTypes.string,
    PropTypes.bool,
    PropTypes.arrayOf(PropTypes.string),
  ]),
});

export const modifyItemFormShape = PropTypes.shape({
  location_description: transformerFieldShape,
});

export const orderDetailsFormShape = PropTypes.shape({
  company: transformerFieldShape,
  description: transformerFieldShape,
  end_date: transformerFieldShape,
  end_use_location_formatted_address: transformerFieldShape,
  need_location_formatted_address: transformerFieldShape,
  order_date: transformerFieldShape,
  order_number: transformerFieldShape,
  user: transformerFieldShape,
});

export function formDataShapeBuilder(formName, formShape, resultShape) {
  return PropTypes.shape({
    defaultOption: PropTypes.shape({
      fields: PropTypes.shape({
        default: PropTypes.shape({
          value: PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          }),
        }),
      }),
      ignoreInForm: PropTypes.bool,
    }),
    id: PropTypes.number,
    [formName]: PropTypes.shape({
      fields: formShape,
    }),
    result: resultShape,
    temp: PropTypes.bool,
    valid: PropTypes.bool,
  });
}

export const bulkEditShape = PropTypes.shape({
  // LINT OVERRIDE #11
  // TECH DEBT
  // Object has undetermined keys
  // eslint-disable-next-line react/forbid-prop-types
  actions: PropTypes.array,
  active: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
  allSelected: PropTypes.bool,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  rowClickDisabled: PropTypes.bool,
  // LINT OVERRIDE #6
  // Object has undetermined keys
  // eslint-disable-next-line react/forbid-prop-types
  selected: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
  ]),
  title: PropTypes.string,
});

export const contentAndStylingShape = PropTypes.shape({
  background_color: PropTypes.string,
  color: PropTypes.string,
  text: PropTypes.string,
});

export const materialConditionShape = PropTypes.shape({
  comments: PropTypes.string,
  id: PropTypes.shape({
    imperfections: PropTypes.string,
    percentage_of_pipe_with_imperfection: PropTypes.string,
    roughness: PropTypes.string,
    surface: PropTypes.string,
    surface_condition: PropTypes.string,
  }),
  od: PropTypes.shape({
    anything_attached: PropTypes.string,
    finish: PropTypes.string,
    pitting_verification: PropTypes.string,
    rust_level: contentAndStylingShape,
    surface_condition: PropTypes.string,
    tong_marks: PropTypes.string,
  }),
  value: PropTypes.string,
});

export const mtrShape = PropTypes.shape({
  api_compliance: PropTypes.string,
  chemistry_compliance: PropTypes.string,
  chemistry_compliance_comment: PropTypes.string,
  comments: PropTypes.string,
  commodity: PropTypes.string,
  couplings_coc: contentAndStylingShape,
  couplings_heat: contentAndStylingShape,
  couplings_mtrs: PropTypes.number,
  customer: PropTypes.string,
  hardness_compliance: PropTypes.string,
  hardness_compliance_comment: PropTypes.string,
  heat_exceptions: PropTypes.string,
  heats: PropTypes.arrayOf(
    PropTypes.shape({
      heat: PropTypes.string,
      number_of_joints: PropTypes.number,
    })
  ),
  manufacturer: PropTypes.string,
  melt_method: PropTypes.string,
  melt_method_comment: PropTypes.string,
  microstructure_compliance: PropTypes.string,
  microstructure_compliance_comment: PropTypes.string,
  mill: PropTypes.string,
  mill_year_number: PropTypes.string,
  mtr_creation_date: PropTypes.string,
  mtr_number: PropTypes.string,
  nde_compliance: PropTypes.string,
  nde_compliance_comment: PropTypes.string,
  pipe_body_heat_s: contentAndStylingShape,
  pipe_body_mtrs: PropTypes.number,
  shipper: PropTypes.string,
  specification: PropTypes.string,
  strength_compliance: PropTypes.string,
  strength_compliance_comment: PropTypes.string,
  total_joints: PropTypes.number,
  toughness_compliance: PropTypes.string,
  toughness_compliance_comment: PropTypes.string,
  uns: PropTypes.string,
});

export const lotSummaryShape = PropTypes.shape({
  afe: PropTypes.string,
  alloy_confirmation: PropTypes.shape({
    api_group: PropTypes.string,
    fail: PropTypes.string,
    joints_tested_with_pmi: PropTypes.number,
    pass: PropTypes.string,
    trade_names: PropTypes.string,
    uns: PropTypes.string,
  }),
  dimensional_inspection_completed: PropTypes.bool,
  drift: PropTypes.shape({
    drift_2nd_alt_performed: PropTypes.number,
    drift_2nd_alt_required: PropTypes.string,
    drift_alt_performed: PropTypes.number,
    drift_alt_required: PropTypes.string,
    drift_standard_performed: PropTypes.number,
    drift_standard_completed: PropTypes.string,
  }),
  inspection_criteria_scope: PropTypes.shape({
    coupling_stencil: PropTypes.string,
    coupling_stencil_joints: PropTypes.number,
    coupling_surface_condition: PropTypes.string,
    coupling_surface_condition_joints: PropTypes.number,
    dimensional_verification: PropTypes.string,
    dimensional_verification_joints: PropTypes.number,
    pipe_body_stencil: PropTypes.string,
    pipe_body_stencil_joints: PropTypes.number,
    pipe_surface_condition: PropTypes.string,
    pipe_surface_condition_joints: PropTypes.number,
    pmi: PropTypes.string,
    pmi_joints: PropTypes.number,
    tally: PropTypes.string,
    tally_joints: PropTypes.number,
    visual_thread_inspection: PropTypes.string,
    visual_thread_inspection_joints: PropTypes.number,
  }),
  // LINT OVERRIDE #2
  // https://github.com/remix-run/react-router/issues/6177
  // External object
  // eslint-disable-next-line react/forbid-prop-types
  joints_mtr: PropTypes.object,
  material_condition: PropTypes.shape({
    used: materialConditionShape,
    unused: materialConditionShape,
    material_description: PropTypes.string,
  }),
  mtrs: PropTypes.arrayOf(mtrShape),
  next_steps: PropTypes.shape({
    general_notes: PropTypes.string,
    listing_actions: PropTypes.string,
    sale_actions: PropTypes.string,
  }),
  performed_at: PropTypes.string,
  performed_by: PropTypes.string,
  performed_date: PropTypes.string,
  performed_for: PropTypes.string,
  po: PropTypes.string,
  quantity_ft: PropTypes.string,
  sap_rep: PropTypes.string,
  storage_conditions_accessibility: PropTypes.shape({
    bumper_ring_condition: PropTypes.string,
    bumper_ring_frequency: PropTypes.string,
    bumper_rings: PropTypes.string,
    coupling_bevel: PropTypes.string,
    coupling_finish: PropTypes.string,
    inspection_status: PropTypes.string,
    location_yard: PropTypes.string,
    material_description: PropTypes.string,
    metal_to_metal: PropTypes.string,
    packaging: PropTypes.string,
    pup_joints: PropTypes.string,
    quantity_ft: PropTypes.number,
    r1_joints: PropTypes.string,
    r2_joints: PropTypes.string,
    r3_joints: PropTypes.string,
    rack: PropTypes.string,
    rack_layer_placement_horizontal_location: PropTypes.string,
    rack_layer_placement_vertical_location: PropTypes.string,
    ring_type: PropTypes.string,
    storage_structure: PropTypes.string,
    total_joints: PropTypes.number,
    weather_at_the_time_of_inspection: PropTypes.string,
    yard_rack_accessibility: PropTypes.string,
  }),
  tally: PropTypes.shape({
    tally_required: PropTypes.string,
    tally_inspected: PropTypes.number,
  }),
  traceability: PropTypes.shape({
    comments: contentAndStylingShape,
    couplings_coc: contentAndStylingShape,
    couplings_heat_s: contentAndStylingShape,
    couplings_mtrs: PropTypes.string,
    etching_type: PropTypes.string,
    paint_stenciling_type: PropTypes.string,
    pipe_body_heat_s: contentAndStylingShape,
    pipe_body_mtrs: PropTypes.string,
    stencil_condition: contentAndStylingShape,
    stencil_etch_match: PropTypes.string,
    total_joints: PropTypes.number,
  }),
  tracking: PropTypes.string,
  type: PropTypes.string,
  visual_body_inspection: PropTypes.shape({
    visual_body_inspection_cracks: PropTypes.number,
    visual_body_inspection_good: PropTypes.number,
    visual_body_inspection_gouges: PropTypes.number,
    visual_body_inspection_laps: PropTypes.number,
    visual_body_inspection_na: PropTypes.number,
    visual_body_inspection_rust: PropTypes.number,
    visual_body_inspection_rustsurfmark: PropTypes.number,
    visual_body_inspection_seams: PropTypes.number,
    visual_body_inspection_tested: PropTypes.number,
  }),
  visual_thread_inspection: PropTypes.shape({
    box_na: PropTypes.string,
    box_pass: PropTypes.number,
    box_reject: PropTypes.string,
    boxes_inspected: PropTypes.number,
    comments: PropTypes.string,
    pin_inspected: PropTypes.number,
    pin_na: PropTypes.string,
    pin_pass: PropTypes.number,
    pin_reject: PropTypes.string,
  }),
  vti_inspection_completed: PropTypes.bool,
  well_charge: PropTypes.string,
  work_order: PropTypes.string,
});

export const metallurgyShape = PropTypes.shape({
  aluminum_max: PropTypes.string,
  aluminum_min: PropTypes.string,
  carbon_max: PropTypes.string,
  carbon_min: PropTypes.string,
  chemical_comments: PropTypes.string,
  chromium_max: PropTypes.string,
  chromium_min: PropTypes.string,
  corrosion_resistance: PropTypes.number,
  corrosion_resistance_name: PropTypes.string,
  density: PropTypes.string,
  description: PropTypes.string,
  element_balance: PropTypes.number,
  files: PropTypes.arrayOf(
    PropTypes.shape({
      client: PropTypes.number,
      content_type: PropTypes.number,
      create_timestamp: PropTypes.string,
      file: PropTypes.string,
      id: PropTypes.number,
      object_id: PropTypes.number,
      order: PropTypes.number,
      original_file_name: PropTypes.string,
      size: PropTypes.number,
      sub_type: PropTypes.string,
      type: PropTypes.string,
      update_timestamp: PropTypes.string,
      user: PropTypes.number,
    })
  ),
  has_chemical_composition: PropTypes.bool,
  id: PropTypes.number,
  iron_max: PropTypes.string,
  iron_min: PropTypes.string,
  manganese_max: PropTypes.string,
  manganese_min: PropTypes.string,
  metallurgy_group: PropTypes.number,
  metallurgy_group_name: PropTypes.string,
  molybdenum_max: PropTypes.string,
  molybdenum_min: PropTypes.string,
  phosphorus_max: PropTypes.string,
  phosphorus_min: PropTypes.string,
  pren_max: PropTypes.string,
  pren_min: PropTypes.string,
  rank: PropTypes.number,
  silicon_max: PropTypes.string,
  silicon_min: PropTypes.string,
  slug: PropTypes.string,
  sulfur_max: PropTypes.string,
  sulfur_min: PropTypes.string,
  synonyms: PropTypes.arrayOf(PropTypes.number),
  tantalum_max: PropTypes.string,
  tantalum_min: PropTypes.string,
  titanium_max: PropTypes.string,
  titanium_min: PropTypes.string,
  tungsten_max: PropTypes.string,
  tungsten_min: PropTypes.string,
  uns: PropTypes.arrayOf(PropTypes.number),
  vanadium_max: PropTypes.string,
  vanadium_min: PropTypes.string,
  // [sic] TODO: fix backed spelling
  wall_tollerance: PropTypes.string,
  yield_strength_max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  yield_strength_min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});

export const conversationShape = PropTypes.shape({
  content_type: PropTypes.number,
  id: PropTypes.number,
  object_id: PropTypes.number,
  recipients: PropTypes.arrayOf(
    PropTypes.shape({
      recipient_id: PropTypes.number,
      user_id: PropTypes.number,
      user_name: PropTypes.string,
    })
  ),
});

export const listingShape = PropTypes.shape({
  am_item_uuid: PropTypes.string,
  am_json: PropTypes.string,
  am_lot_uuid: PropTypes.string,
  attributes: PropTypes.shape({
    mill_year: PropTypes.string,
    manufacturer: PropTypes.string,
  }),
  brand: PropTypes.string,
  brand_status: PropTypes.string,
  condition: PropTypes.string,
  conversation: conversationShape,
  create_timestamp: PropTypes.string,
  criteria: criteriaShape,
  description: PropTypes.string,
  end_finish: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({
      category: PropTypes.number,
      category_name: PropTypes.string,
      code: PropTypes.string,
      description: PropTypes.string,
      // TODO: define this properly when files are present
      // LINT OVERRIDE #8
      // TECH DEBT
      // Array has undetermined elements
      // eslint-disable-next-line react/forbid-prop-types
      files: PropTypes.array,
      id: PropTypes.number,
      licensee: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        slug: PropTypes.string,
      }),
      name: PropTypes.string,
      thread_type: PropTypes.number,
      thread_type_name: PropTypes.string,
    }),
  ]),
  // LINT OVERRIDE #8
  // TECH DEBT
  // Array has undetermined elements
  // eslint-disable-next-line react/forbid-prop-types
  files: PropTypes.array,
  following: PropTypes.bool,
  id: PropTypes.number,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      image: PropTypes.string,
      original_file_name: PropTypes.string,
    })
  ),
  listing_expiration: PropTypes.string,
  location: PropTypes.number,
  location_country: PropTypes.string,
  location_description: PropTypes.string,
  location_latitude: PropTypes.number,
  location_longitude: PropTypes.number,
  location_place_id: PropTypes.string,
  lot_joints: PropTypes.shape({
    total_count: PropTypes.number,
    data: PropTypes.arrayOf(listingMaterialDataShape),
  }),
  lot_summary: lotSummaryShape,
  metallurgy: PropTypes.oneOfType([PropTypes.number, metallurgyShape]),
  name: PropTypes.string,
  price: PropTypes.string,
  price_uom: PropTypes.number,
  quantity: PropTypes.string,
  quantity_uom: PropTypes.number,
  quantity_uom_name: PropTypes.string,
  slug: PropTypes.string,
  status: PropTypes.string,
  supply_opportunity: PropTypes.number,
  title: PropTypes.string,
  update_timestamp: PropTypes.string,
  url: PropTypes.string,
});

export const prospectShape = PropTypes.shape({
  birth_date: PropTypes.bool,
  birth_year: PropTypes.bool,
  countries: PropTypes.bool,
  education: PropTypes.bool,
  emails: PropTypes.bool,
  experience: PropTypes.bool,
  facebook_id: PropTypes.bool,
  facebook_url: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  facebook_username: PropTypes.bool,
  first_name: PropTypes.bool,
  full_name: PropTypes.string,
  gender: PropTypes.string,
  github_url: PropTypes.bool,
  github_username: PropTypes.bool,
  id: PropTypes.string,
  industry: PropTypes.string,
  interests: PropTypes.bool,
  job_company_facebook_url: PropTypes.bool,
  job_company_founded: PropTypes.bool,
  job_company_id: PropTypes.bool,
  job_company_industry: PropTypes.bool,
  job_company_linkedin_id: PropTypes.bool,
  job_company_linkedin_url: PropTypes.bool,
  job_company_location_address_line_2: PropTypes.bool,
  job_company_location_continent: PropTypes.bool,
  job_company_location_country: PropTypes.bool,
  job_company_location_geo: PropTypes.bool,
  job_company_location_locality: PropTypes.bool,
  job_company_location_metro: PropTypes.bool,
  job_company_location_name: PropTypes.bool,
  job_company_location_postal_code: PropTypes.bool,
  job_company_location_region: PropTypes.bool,
  job_company_location_street_address: PropTypes.bool,
  job_company_name: PropTypes.string,
  job_company_size: PropTypes.bool,
  job_company_twitter_url: PropTypes.bool,
  job_company_website: PropTypes.string,
  job_last_updated: PropTypes.bool,
  job_start_date: PropTypes.bool,
  job_title: PropTypes.string,
  job_title_levels: PropTypes.arrayOf(PropTypes.string),
  job_title_role: PropTypes.string,
  job_title_sub_role: PropTypes.string,
  last_initial: PropTypes.bool,
  last_name: PropTypes.bool,
  linkedin_id: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  linkedin_url: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  linkedin_username: PropTypes.bool,
  location_address_line_2: PropTypes.bool,
  location_continent: PropTypes.bool,
  location_country: PropTypes.bool,
  location_geo: PropTypes.bool,
  location_last_updated: PropTypes.bool,
  location_locality: PropTypes.bool,
  location_metro: PropTypes.bool,
  location_name: PropTypes.string,
  location_postal_code: PropTypes.bool,
  location_region: PropTypes.bool,
  location_street_address: PropTypes.bool,
  middle_initial: PropTypes.bool,
  middle_name: PropTypes.bool,
  mobile_phone: PropTypes.bool,
  personal_emails: PropTypes.bool,
  phone_numbers: PropTypes.bool,
  profiles: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  ps_account_exists: PropTypes.bool,
  regions: PropTypes.bool,
  skills: PropTypes.bool,
  street_addresses: PropTypes.bool,
  twitter_url: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  twitter_username: PropTypes.bool,
  version_status: PropTypes.shape({
    // LINT OVERRIDE #8
    // TECH DEBT
    // Array has undetermined elements
    // eslint-disable-next-line react/forbid-prop-types
    contains: PropTypes.array,
    current_version: PropTypes.string,
    previous_version: PropTypes.string,
    status: PropTypes.string,
  }),
  work_email: PropTypes.bool,
});

export const endUserDashboardVerticalOverviewShape = PropTypes.shape({
  quoteItems: PropTypes.shape({
    countries: PropTypes.number,
    not_quoted: PropTypes.number,
    ordered: PropTypes.number,
    quoted: PropTypes.number,
  }),
  rfq: PropTypes.shape({
    active: PropTypes.number,
    count: PropTypes.number,
    name: PropTypes.string,
    status: PropTypes.shape({
      buckets: PropTypes.arrayOf(
        PropTypes.shape({
          val: PropTypes.string,
          count: PropTypes.number,
          name: PropTypes.string,
        })
      ),
      missing: PropTypes.shape({
        count: PropTypes.number,
      }),
    }),
    unsubmitted: PropTypes.number,
    val: PropTypes.string,
  }),
  rfqItems: PropTypes.shape({
    active: PropTypes.number,
    count: PropTypes.number,
    countries: PropTypes.number,
    name: PropTypes.string,
    status: PropTypes.shape({
      buckets: PropTypes.arrayOf(
        PropTypes.shape({
          val: PropTypes.string,
          count: PropTypes.number,
          name: PropTypes.string,
        })
      ),
      missing: PropTypes.shape({
        count: PropTypes.number,
      }),
    }),
    unsubmitted: PropTypes.number,
    val: PropTypes.string,
  }),
  supply: PropTypes.shape({
    active: PropTypes.number,
    count: PropTypes.number,
    countries: PropTypes.number,
    name: PropTypes.string,
    status: PropTypes.shape({
      buckets: PropTypes.arrayOf(
        PropTypes.shape({
          val: PropTypes.string,
          count: PropTypes.number,
          name: PropTypes.string,
        })
      ),
      missing: PropTypes.shape({
        count: PropTypes.number,
      }),
    }),
    unsubmitted: PropTypes.number,
    val: PropTypes.string,
  }),
  // LINT OVERRIDE #6
  // Object has undetermined keys
  // eslint-disable-next-line react/forbid-prop-types
  wishlist: PropTypes.object,
});

export const userMetaShape = PropTypes.shape({
  account_type: PropTypes.number,
  company: PropTypes.number,
  company_name: PropTypes.string,
  company_type: PropTypes.arrayOf(PropTypes.number),
  company_type_name: PropTypes.arrayOf(PropTypes.string),
  create_timestamp: PropTypes.string,
  google_address: PropTypes.number,
  job_function: PropTypes.arrayOf(PropTypes.number),
  job_function_name: PropTypes.arrayOf(PropTypes.string),
  job_title: PropTypes.string,
  location_description: PropTypes.string,
  place_id: PropTypes.string,
  submitter: PropTypes.number,
  update_timestamp: PropTypes.string,
  user: PropTypes.number,
});

export const endUserSupplyItemShape = PropTypes.shape({
  active_status: PropTypes.number,
  api_internal_pressure: PropTypes.number,
  attribute_values: PropTypes.arrayOf(PropTypes.number),
  body_yield_strength: PropTypes.number,
  brand: PropTypes.string,
  category: PropTypes.number,
  category_name: PropTypes.string,
  client: PropTypes.number,
  collapse_strength: PropTypes.number,
  condition: PropTypes.number,
  condition_name: PropTypes.string,
  create_timestamp: PropTypes.string,
  drift: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  end_finish: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
  end_finish_category: PropTypes.number,
  end_finish_licensee: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
  end_finish_name: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  end_finish_other: PropTypes.number,
  end_finish_thread_type: PropTypes.number,
  end_user: PropTypes.number,
  external_conversation: conversationShape,
  // LINT OVERRIDE #8
  // TECH DEBT
  // Array has undetermined elements
  // eslint-disable-next-line react/forbid-prop-types
  files: PropTypes.array,
  id: PropTypes.number,
  // LINT OVERRIDE #8
  // TECH DEBT
  // Array has undetermined elements
  // eslint-disable-next-line react/forbid-prop-types
  images: PropTypes.array,
  implied_metallurgy_group_name: PropTypes.string,
  inside_diameter: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  internal_conversation: conversationShape,
  internal_following: PropTypes.bool,
  manufacture_year: PropTypes.number,
  manufacturer: PropTypes.string,
  manufacturer_other: PropTypes.string,
  manufacturing_process: PropTypes.string,
  metallurgy: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
  metallurgy_group: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
  metallurgy_name: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  metallurgy_other: PropTypes.string,
  min_yield: PropTypes.number,
  mtrs: PropTypes.arrayOf(mtrShape),
  name: PropTypes.string,
  notes: PropTypes.string,
  od: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  price_uom: PropTypes.number,
  quantity: PropTypes.number,
  quantity_uom: PropTypes.number,
  quantity_uom_name: PropTypes.string,
  range: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  range_name: PropTypes.string,
  status: PropTypes.string,
  status_name: PropTypes.string,
  submitter: PropTypes.number,
  supply_location: PropTypes.string,
  supply_opportunity: PropTypes.number,
  temp_market_inventory_id: PropTypes.number,
  thread_configuration: PropTypes.number,
  update_timestamp: PropTypes.string,
  user: PropTypes.oneOfType([PropTypes.number, userShape]),
  user_meta: userMetaShape,
  verified_date: PropTypes.string,
  wall: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  weight_per_foot: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  yard_name: PropTypes.string,
});

// Builds our asyncTreeRequester shape around content delivered from the API
export const asyncTreeRequesterTableShapeResultBuilder = (docs, facets) => ({
  docs: PropTypes.arrayOf(PropTypes.shape(docs)),
  facets: PropTypes.shape(facets),
  // LINT OVERRIDE #6
  // Object has undetermined keys
  // eslint-disable-next-line react/forbid-prop-types
  filters: PropTypes.object,
  limit: PropTypes.number,
  num_pages: PropTypes.number,
  page: PropTypes.number,
  pagination: paginationShape,
  sort: sortShape,
  sort_fields: PropTypes.arrayOf(PropTypes.string),
  total: PropTypes.number,
});

export const asyncTreeRequesterShapeBuilder = async (result) => ({
  error: PropTypes.bool,
  filters: PropTypes.object,
  isDeleting: PropTypes.bool,
  isSaving: PropTypes.bool,
  loading: PropTypes.bool,
  result: PropTypes.shape(result),
});

export const attributeTableItemShape = PropTypes.shape({
  name: PropTypes.string,
  id: PropTypes.string,
  content_type_counts: PropTypes.arrayOf(
    PropTypes.shape({
      User: PropTypes.number,
    })
  ),
  update_timestamp: PropTypes.string,
  document_type: PropTypes.string,
  is_parent: PropTypes.bool,
  create_timestamp: PropTypes.string,
  timestamp: PropTypes.string,
  _version_: PropTypes.number,
  value: PropTypes.string,
  category_id: PropTypes.number,
  client_id: PropTypes.number,
  active_status: PropTypes.number,
  attribute_id: PropTypes.number,
  category: PropTypes.string,
  content_type_id: PropTypes.number,
  count: PropTypes.number,
  object_id: PropTypes.number,
  type_name: PropTypes.string,
});

export const actionItemsTableRfqMeta = PropTypes.shape({
  category: PropTypes.arrayOf(
    PropTypes.shape({
      ...idNameObject,
      object_prefix: PropTypes.string,
    })
  ),
  metallurgy: PropTypes.arrayOf(
    PropTypes.shape({
      ...idNameObject,
      metallurgy_group: PropTypes.number,
      slug: PropTypes.string,
      rank: PropTypes.number,
      density: PropTypes.string,
    })
  ),
  metallurgy_group: PropTypes.arrayOf(
    PropTypes.shape({
      ...idNameObject,
      description: PropTypes.string,
      name_long: PropTypes.string,
      slug: PropTypes.string,
    })
  ),
  end_finish: PropTypes.arrayOf(
    PropTypes.shape({
      ...idNameObject,
      licensee: PropTypes.number,
      thread_type: PropTypes.number,
      category: PropTypes.number,
    })
  ),
  end_finish_licensee: PropTypes.arrayOf(
    PropTypes.shape({
      ...idNameObject,
      slug: PropTypes.string,
    })
  ),
  manufacturer: idNameArr,
  end_finish_category: idNameArr,
  end_finish_thread_type: idNameArr,
  weight_uom: idNameArr,
  length_uom: idNameArr,
  pressure_uom: idNameArr,
  temperature_uom: idNameArr,
  chemical_content_uom: idNameArr,
  thread_config: idNameArr,
  manufacturing_process: idNameArr,
  standard_od: PropTypes.arrayOf(
    PropTypes.shape({
      ...idNameObject,
      od: PropTypes.string,
      alternative_name: PropTypes.string,
    })
  ),
  standard_weight: arrayOf(
    PropTypes.shape({
      ...idNameObject,
      weight_per_foot: PropTypes.string,
      wall: PropTypes.string,
      standard_od: PropTypes.number,
    })
  ),
  range: PropTypes.arrayOf(
    PropTypes.shape({
      ...idNameObject,
      category: PropTypes.number,
    })
  ),
  brand: PropTypes.arrayOf(
    PropTypes.shape({
      ...idNameObject,
      brand: PropTypes.string,
      name_long: PropTypes.string,
      origin: PropTypes.string,
      description: PropTypes.string,
      external_brand: PropTypes.bool,
    })
  ),
  condition: idNameArr,
  packaging: idNameArr,
  delivery_type: PropTypes.arrayOf(
    PropTypes.shape({
      ...idNameObject,
      name_long: PropTypes.string,
      description: PropTypes.string,
    })
  ),
  well_type: PropTypes.arrayOf(
    PropTypes.shape({
      ...idNameObject,
      type: PropTypes.number,
      type_display: PropTypes.string,
    })
  ),
  application_type: idNameArr,
  file_content_types: PropTypes.shape({
    RFQWell: PropTypes.number,
    ListingPage: PropTypes.number,
    RFQ: PropTypes.number,
    SupplyOpportunity: PropTypes.number,
    SupplyOpportunityItem: PropTypes.number,
    Metallurgy: PropTypes.number,
    RFQFacilitatorQuotePDF: PropTypes.number,
    Conversation: PropTypes.number,
    SimplifiedInquiry: PropTypes.number,
    SimplifiedSupplyOpportunity: PropTypes.number,
    Company: PropTypes.number,
    Order: PropTypes.number,
  }),
  mtr_content_types: PropTypes.shape({
    SupplyOpportunityItem: PropTypes.number,
  }),
  conversation_content_types: PropTypes.shape({
    SupplyOpportunity: PropTypes.number,
    SupplyOpportunityItem: PropTypes.number,
    RFQ: PropTypes.number,
    Company: PropTypes.number,
    AuthUser: PropTypes.number,
    Campaign: PropTypes.number,
    Engagement: PropTypes.number,
    ListingPage: PropTypes.number,
  }),
  attribute_content_types: PropTypes.shape({
    RFQ: PropTypes.number,
    RFQItem: PropTypes.number,
    RFQWell: PropTypes.number,
    Company: PropTypes.number,
    SupplyOpportunity: PropTypes.number,
    SupplyOpportunityItem: PropTypes.number,
    UserProduct: PropTypes.number,
    AuthUser: PropTypes.number,
    Campaign: PropTypes.number,
    Engagement: PropTypes.number,
    ListingPage: PropTypes.number,
  }),
  entity_schema_content_types: PropTypes.shape({
    Contact: PropTypes.number,
  }),
  priority: idNameArr,
  quantity_uom: idNameArr,
  timing_uom: idNameArr,
  rfq_attribute: PropTypes.arrayOf(
    PropTypes.shape({
      ...idNameObject,
      slug: PropTypes.string,
      description: PropTypes.string,
      tier_1: PropTypes.string,
      tier_2: PropTypes.string,
      tier_3: PropTypes.string,
    })
  ),
  rfq_personnel_role: PropTypes.arrayOf(personnelRoleShape),
  tag_content_types: PropTypes.shape({
    AuthUser: PropTypes.number,
    Company: PropTypes.number,
  }),
  interaction_type: idNameArr,
  crm_content_types: PropTypes.shape({
    engagement: PropTypes.number,
    interaction: PropTypes.number,
    actionitem: PropTypes.number,
  }),
  interaction_content_types: PropTypes.shape({
    Engagement: PropTypes.number,
  }),
  actionitem_content_types: PropTypes.shape({
    Engagement: PropTypes.number,
    AuthUser: PropTypes.number,
  }),
  order_content_types: PropTypes.shape({
    RFQ: PropTypes.number,
    SupplyOpportunity: PropTypes.number,
  }),
  order_item_content_types: PropTypes.shape({
    RFQQuoteItem: PropTypes.number,
    SupplyOpportunityItem: PropTypes.number,
  }),
  supply_opportunity_outcomes: idNameArr,
  object_personnel_attributes: PropTypes.shape({
    'supply opportunity': idNameArr,
    interaction: idNameArr,
    engagement: idNameArr,
  }),
  actionitem_statuses: idNameArr,
});

const generalForm = {};
generalForm.propTypes = {
  disabled: PropTypes.bool,
  data: PropTypes.shape({}),
  canContinue: PropTypes.bool,
  onSuccess: PropTypes.func,
};

const formRendered = {};
formRendered.propTypes = {
  disabled: PropTypes.bool,
  values: PropTypes.shape({
    title: PropTypes.string,
    priority: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  }),
  errors: PropTypes.shape({
    name: PropTypes.string,
  }),
  touched: PropTypes.shape({
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  }),
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  handleSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
  status: PropTypes.shape({
    error: PropTypes.string,
  }),
  setFieldValue: PropTypes.func,
};

const inputContainer = {};
inputContainer.propTypes = {
  disabled: PropTypes.bool,
  setEditorState: PropTypes.func,
  editorState: PropTypes.shape({}),
  placeholder: PropTypes.string,
  disableToolbar: PropTypes.bool,
};

export const sessionRoleShape = PropTypes.shape({
  company: PropTypes.shape({
    icon: PropTypes.string,
    id: PropTypes.number,
    logo: PropTypes.string,
    name: PropTypes.string,
  }),
  user: PropTypes.shape({
    email: PropTypes.string,
    first_name: PropTypes.string,
    id: PropTypes.number,
    last_name: PropTypes.string,
    title: PropTypes.string,
  }),
});

const interactionForm = {};
interactionForm.propTypes = {
  interactionTypeOptions: idNameArr,
};

const supplyGroupDetailsForm = {};
supplyGroupDetailsForm.propTypes = {
  basePath: PropTypes.string,
  hasFacilitator: PropTypes.oneOfType([sessionRoleShape, PropTypes.bool]),
  rfqMetadata: PropTypes.shape({}),
  editorState: PropTypes.shape({}),
  setEditorState: PropTypes.func,
  engagementId: PropTypes.number,
  companyId: PropTypes.number,
  interactionId: PropTypes.number,
  customerId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  clientId: PropTypes.number,
};

export const formProps = {
  // Shared Form Props
  generalForm,
  formRendered,
  inputContainer,
  // Specific Form Props
  interactionForm,
  supplyGroupDetailsForm,
};

export const companyProjectsProps = PropTypes.shape({
  match: PropTypes.arrayOf(
    PropTypes.shape({
      params: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
        })
      ),
    })
  ),
});

const lookup = {};
lookup.propTypes = {
  style: PropTypes.shape({}),
  placeholder: PropTypes.string,
  selectedItem: PropTypes.shape({}),
  clientId: PropTypes.number,
  searchType: PropTypes.shape({}),
  reset: PropTypes.bool,
};

const sidebar = {};
sidebar.propTypes = {
  options: PropTypes.shape({
    tab: PropTypes.string,
    engagement: PropTypes.shape({}),
  }),
};

const card = {};
card.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  active_status_name: PropTypes.string,
  priority_name: PropTypes.string,
};

const summarySidebar = {};
summarySidebar.propTypes = {
  summmaryResult: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    create_timestamp: PropTypes.string,
  }),
  rfqMeta: PropTypes.shape({}),
};

export const projectProps = PropTypes.shape({
  lookup,
  sidebar,
  card,
  messaging: companyProjectsProps,
  summarySidebar,
});

export const projectManagementProps = {
  match: PropTypes.shape({
    url: PropTypes.string,
  }),
};

const globalFilter = {};
globalFilter.propTypes = {
  options: PropTypes.shape({
    onFilter: PropTypes.func,
    type: PropTypes.string,
    localFilters: PropTypes.arrayOf(PropTypes.string),
  }),
  matching: PropTypes.string,
  customers: PropTypes.string,
  setFilteredMatches: PropTypes.func,
  dropdowns: PropTypes.bool,
  styles: PropTypes.shape({}),
};

const accordian = {};
accordian.propTypes = {
  style: PropTypes.shape({}),
  total: PropTypes.number,
  resetFilterValues: PropTypes.func,
  selectedValues: PropTypes.shape({}),
  facetGroups: PropTypes.arrayOf(PropTypes.shape({})),
  onChangeCheckboxList: PropTypes.func,
};

const selectDropdown = {};
selectDropdown.propTypes = {
  selectedValues: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  facetGroups: PropTypes.arrayOf(PropTypes.shape({})),
  onChangeCheckboxList: PropTypes.func,
  omittedFilters: PropTypes.arrayOf(PropTypes.string),
};

const dropdown = {};
dropdown.propTypes = {
  name: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  selectedFacetValues: PropTypes.shape({}),
  onChangeCheckboxList: PropTypes.func,
};

export const globalInterestsFilterProps = PropTypes.shape({
  globalFilter,
  accordian,
  selectDropdown,
  dropdown,
});

export const ingestedUserShape = PropTypes.shape({
  success: PropTypes.bool,
  user_id: PropTypes.number,
  error: PropTypes.string,
});

export const ingestedCompanyShape = PropTypes.shape({
  success: PropTypes.bool,
  company_id: PropTypes.number,
  error: PropTypes.string,
});

export const internalRelationshipShape = PropTypes.shape({
  client: PropTypes.number,
  create_timestamp: PropTypes.string,
  description: PropTypes.string,
  id: PropTypes.number,
  related_user: PropTypes.number,
  related_user_name: PropTypes.string,
  type: PropTypes.string,
  update_timestamp: PropTypes.string,
  user: PropTypes.number,
});

export const attributeTableItems = PropTypes.shape({
  ...idNameObject,
  update_timestamp: PropTypes.string,
  document_type: PropTypes.string,
  is_parent: PropTypes.bool,
  create_timestamp: PropTypes.string,
  timestamp: PropTypes.string,
  _version_: PropTypes.number,
  description: PropTypes.string,
  slug: PropTypes.string,
  parent_id: PropTypes.number,
  linkedin_url: PropTypes.string,
  domain: PropTypes.string,
  priority: PropTypes.number,
  status: PropTypes.number,
  ticker: PropTypes.string,
  parent_name: PropTypes.string,
  client_id: numArr,
  synonym: strArr,
  company_type: numArr,
  company_type_name: strArr,
  attribute_values: numArr,
  autocomplete_character_count: PropTypes.number,
  status_name: PropTypes.string,
  priority_name: PropTypes.string,
  priority_rank: PropTypes.number,
  tag: strArr,
  following: numArr,
});

export const facilitatorWishlistTableItem = PropTypes.shape({
  id: PropTypes.number,
  update_timestamp: PropTypes.string,
  document_type: PropTypes.string,
  is_parent: PropTypes.bool,
  create_timestamp: PropTypes.string,
  timestamp: PropTypes.string,
  _version_: PropTypes.number,
  od: PropTypes.number,
  inside_diameter: PropTypes.number,
  wall: PropTypes.number,
  weight_per_foot: PropTypes.number,
  min_yield: PropTypes.number,
  technical_details_confirmed: PropTypes.bool,
  burst_min: PropTypes.number,
  collapse_min: PropTypes.number,
  tensile_strength_min: PropTypes.number,
  drift: PropTypes.number,
  quantity: PropTypes.number,
  name: PropTypes.string,
  deleted: PropTypes.bool,
  category_id: PropTypes.number,
  range_id: PropTypes.number,
  user_id: PropTypes.number,
  submitter_user_id: PropTypes.number,
  category: PropTypes.number,
  user: PropTypes.number,
  category_name: PropTypes.string,
  category_object_prefix: PropTypes.string,
  user_name: PropTypes.string,
  range: PropTypes.string,
  metallurgy: numArr,
  metallurgy_name: strArr,
  end_finish: numArr,
  end_finish_name: strArr,
  alternative_alloys: strArr,
  alternative_connections: strArr,
});

export const listingsTableItems = PropTypes.shape({
  id: PropTypes.number,
  update_timestamp: PropTypes.string,
  document_type: PropTypes.string,
  is_parent: PropTypes.bool,
  create_timestamp: PropTypes.string,
  timestamp: PropTypes.string,
  _version_: PropTypes.number,
  name: PropTypes.string,
  slug: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  od: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  wall: PropTypes.number,
  drift: PropTypes.number,
  range: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  category: PropTypes.number,
  min_pren: PropTypes.number,
  range_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  min_yield: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  end_finish: PropTypes.number,
  metallurgy: PropTypes.number,
  range_name: PropTypes.string,
  category_name: PropTypes.string,
  end_finish_name: PropTypes.string,
  inside_diameter: PropTypes.number,
  metallurgy_name: PropTypes.string,
  weight_per_foot: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  collapse_strength: PropTypes.number,
  body_yield_strength: PropTypes.number,
  api_internal_pressure: PropTypes.number,
  category_object_prefix: PropTypes.string,
  mill_year: PropTypes.string,
  manufacturer: PropTypes.string,
  price: PropTypes.number,
  price_uom: PropTypes.number,
  quantity_uom: PropTypes.number,
  quantity: PropTypes.number,
  listing_expiration: PropTypes.string,
  status: PropTypes.string,
  client_id: PropTypes.number,
  location_id: PropTypes.number,
  brand: PropTypes.number,
  brand_name: PropTypes.string,
  condition_name: PropTypes.string,
  location_latitude: PropTypes.number,
  location_longitude: PropTypes.number,
  location_description: PropTypes.string,
  location_place_id: PropTypes.string,
  location_country: PropTypes.string,
  status_display: PropTypes.string,
});

export const campaignTableItems = PropTypes.shape({
  id: PropTypes.number,
  update_timestamp: PropTypes.string,
  document_type: PropTypes.string,
  create_timestamp: PropTypes.string,
  timestamp: PropTypes.string,
  _version_: PropTypes.number,
  name: PropTypes.string,
  from_email: PropTypes.string,
  from_name: PropTypes.string,
  subject: PropTypes.string,
  client_id: PropTypes.number,
  created_by_id: PropTypes.number,
  status: PropTypes.string,
  message_html: PropTypes.string,
  uuid: PropTypes.string,
  schedule_date: PropTypes.string,
});

export const campaignShape = PropTypes.shape({
  cio_trigger_id: PropTypes.number,
  conversation: conversationShape,
  create_timestamp: PropTypes.string,
  // LINT OVERRIDE #8
  // TECH DEBT
  // Array has undetermined elements
  // eslint-disable-next-line react/forbid-prop-types
  events: PropTypes.array,
  following: PropTypes.bool,
  from_email: PropTypes.string,
  from_name: PropTypes.string,
  id: PropTypes.number,
  // LINT OVERRIDE #2
  // https://github.com/remix-run/react-router/issues/6177
  // External object
  // eslint-disable-next-line react/forbid-prop-types
  message_draft: PropTypes.object,
  message_html: PropTypes.string,
  name: PropTypes.string,
  status: PropTypes.string,
  status_name: PropTypes.string,
  subject: PropTypes.string,
  update_timestamp: PropTypes.string,
  schedule_date: PropTypes.string,
  uuid: PropTypes.string,
});

export const messageShape = PropTypes.shape({
  active_status: PropTypes.number,
  active_status_name: PropTypes.string,
  client_id: PropTypes.number,
  conversation: PropTypes.number,
  create_timestamp: PropTypes.string,
  document_type: PropTypes.string,
  from_user: PropTypes.oneOfType([PropTypes.number, PropTypes.shape({})]),
  from_user_name: PropTypes.string,
  id: PropTypes.number,
  json: PropTypes.shape({
    action: PropTypes.string,
    subject: PropTypes.string,
    type: PropTypes.string,
  }),
  message_type: PropTypes.number,
  message_type_name: PropTypes.string,
  object_id: PropTypes.number,
  source: PropTypes.number,
  source_name: PropTypes.string,
  timestamp: PropTypes.string,
  title: PropTypes.string,
  to_users: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.shape({})])
  ),
  type_name: PropTypes.string,
  update_timestamp: PropTypes.string,
  uuid: PropTypes.string,
  _version_: PropTypes.number,
});

export const widthShape = PropTypes.shape({
  expandOnHover: PropTypes.bool,
  fontSize: PropTypes.string,
  cellProps: PropTypes.shape({}),
  size: PropTypes.string,
  accessor: PropTypes.func,
  renderFn: PropTypes.func,
  ellipsis: PropTypes.bool,
  // LINT OVERRIDE #6
  // Object has undetermined keys
  // eslint-disable-next-line react/forbid-prop-types
  suffixStyle: PropTypes.object,
  suffix: PropTypes.string,
  prefix: PropTypes.string,
  formatter: PropTypes.func,
});

export const supplyInterestAttributeShape = PropTypes.shape({
  active_status: PropTypes.number,
  category_id: PropTypes.number,
  category_name: PropTypes.string,
  client: PropTypes.number,
  content_type: PropTypes.number,
  create_timestamp: PropTypes.string,
  id: PropTypes.number,
  object_id: PropTypes.number,
  update_timestamp: PropTypes.string,
  value: PropTypes.string,
  value_id: PropTypes.number,
});

export const personnelShape = PropTypes.shape({
  client: PropTypes.number,
  id: PropTypes.number,
  rfq: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  role: PropTypes.oneOfType([
    PropTypes.arrayOf(personnelRoleShape),
    personnelRoleShape,
    PropTypes.number,
    PropTypes.string,
    personnelRoleShape,
  ]),
  user: PropTypes.oneOfType([userShape, PropTypes.string]),
});

export const priorityShape = PropTypes.shape({
  id: PropTypes.number,
  priority: PropTypes.number,
  priority_name: PropTypes.string,
  rfq: PropTypes.number,
});

export const orderShape = PropTypes.shape({
  active_status: PropTypes.number,
  am_order_uuid: PropTypes.string,
  content_type: PropTypes.number,
  create_timestamp: PropTypes.string,
  deleted_order_items: PropTypes.arrayOf(orderItemShape),
  end_date: PropTypes.string,
  end_use_location: PropTypes.number,
  end_use_location_formatted_address: PropTypes.string,
  end_use_location_place_id: PropTypes.string,
  id: PropTypes.number,
  need_location: PropTypes.number,
  need_location_formatted_address: PropTypes.string,
  need_location_place_id: PropTypes.string,
  notes_draft: PropTypes.string,
  object_id: PropTypes.number,
  order_date: PropTypes.string,
  order_items: PropTypes.arrayOf(orderItemShape),
  order_number: PropTypes.string,
  status: PropTypes.string,
  update_timestamp: PropTypes.string,
});

export const wellShape = PropTypes.shape({
  client_id: PropTypes.number,
  create_timestamp: PropTypes.string,
  deleted: PropTypes.bool,
  document_type: PropTypes.string,
  id: PropTypes.number,
  is_parent: PropTypes.bool,
  rfq: PropTypes.number,
  submitter_user_id: PropTypes.number,
  timestamp: PropTypes.string,
  update_timestamp: PropTypes.string,
  user: PropTypes.number,
  well_name: PropTypes.string,
  _version_: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});

export const rfqSummaryShape = PropTypes.shape({
  assessment: PropTypes.shape({
    facilitator_personnel: PropTypes.arrayOf(personnelShape),
    priority: priorityShape,
  }),
  client: PropTypes.number,
  create_timestamp: PropTypes.string,
  customer_personnel: PropTypes.arrayOf(personnelShape),
  end_use_location: PropTypes.number,
  end_user: PropTypes.number,
  end_user_name: PropTypes.string,
  engagement: PropTypes.number,
  external_conversation: conversationShape,
  external_following: PropTypes.bool,
  files: PropTypes.arrayOf(PropTypes.number),
  id: PropTypes.number,
  images: PropTypes.arrayOf(PropTypes.number),
  inquiring_company: PropTypes.number,
  inquiring_company_name: PropTypes.string,
  internal_conversation: conversationShape,
  internal_following: PropTypes.bool,
  need_date: PropTypes.string,
  need_location: PropTypes.number,
  need_location_country: PropTypes.string,
  need_location_description: PropTypes.string,
  need_location_place_id: PropTypes.string,
  order: orderShape,
  order_eligible: PropTypes.bool,
  order_status: PropTypes.string,
  project_name: PropTypes.string,
  reference_number: PropTypes.string,
  rfq_item: PropTypes.arrayOf(opportunityItemShape),
  // LINT OVERRIDE #8
  // TECH DEBT
  // Array has undetermined elements
  // eslint-disable-next-line react/forbid-prop-types
  rfq_item_override: PropTypes.array,
  rfq_well: PropTypes.arrayOf(wellShape),
  simplified_inquiry: PropTypes.string,
  status: PropTypes.string,
  status_display: PropTypes.string,
  submitter: PropTypes.number,
  update_timestamp: PropTypes.string,
  user: customerShape,
  user_meta: userMetaShape,
});

export const summaryResultShape = PropTypes.shape({
  conversation: conversationShape,
  create_timestamp: PropTypes.string,
  description: PropTypes.string,
  domain: PropTypes.string,
  family: PropTypes.arrayOf(
    PropTypes.shape({
      children: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
        })
      ),
      focus: PropTypes.bool,
      id: PropTypes.number,
      left: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
        })
      ),
      parents: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
        })
      ),
      right: PropTypes.arrayOf(PropTypes.string),
      siblings: PropTypes.arrayOf(PropTypes.string),
      spouses: PropTypes.arrayOf(PropTypes.string),
    })
  ),
  following: PropTypes.bool,
  icon: PropTypes.string,
  id: PropTypes.number,
  linkedin_description: PropTypes.string,
  linkedin_title: PropTypes.string,
  linkedin_url: PropTypes.string,
  merged_to: PropTypes.shape({}),
  name: PropTypes.string,
  parent: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  priority: PropTypes.number,
  priority_name: PropTypes.string,
  rank: PropTypes.number,
  slug: PropTypes.string,
  status: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  staus_name: PropTypes.string,
  synonyms: PropTypes.arrayOf(PropTypes.number),
  tags: PropTypes.arrayOf(PropTypes.number),
  ticker: PropTypes.string,
  update_timestamp: PropTypes.string,
});

export const roleDataShape = PropTypes.shape({
  value: PropTypes.string,
  label: PropTypes.string,
  sessionRole: PropTypes.string,
  rank: PropTypes.number,
});

export const addCompanySidebarShape = PropTypes.shape({
  isNewCompany: PropTypes.bool,
  maxWidth: PropTypes.string,
  style: PropTypes.string,
});

export const onboardingMetaShape = PropTypes.shape({
  account_type: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  application_type: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  category: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      object_prefix: PropTypes.string,
    })
  ),
  company_status: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  company_type: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      description: PropTypes.string,
    })
  ),
  contact_status: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  email_preferences_frequency: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  end_finish: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      category: PropTypes.number,
      licensee: PropTypes.number,
      thread_type: PropTypes.number,
    })
  ),
  end_finish_category: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  end_finish_licensee: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      slug: PropTypes.string,
    })
  ),
  end_finish_thread_type: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  job_function: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  length_uom: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  metallurgy: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      density: PropTypes.string,
      metallurgy_group: PropTypes.number,
      rank: PropTypes.number,
      slug: PropTypes.string,
    })
  ),
  metallurgy_group: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      description: PropTypes.string,
      name_long: PropTypes.string,
      slug: PropTypes.string,
    })
  ),
  // Empty array in meta
  // LINT OVERRIDE #8
  // TECH DEBT
  // Array has undetermined elements
  // eslint-disable-next-line react/forbid-prop-types
  product_category: PropTypes.array,
  quantity_uom: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  range: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      category: PropTypes.number,
    })
  ),
  shore_type: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  standard_od: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      od: PropTypes.string,
    })
  ),
  standard_weight: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      standard_od: PropTypes.number,
      wall: PropTypes.string,
      weight_per_foot: PropTypes.string,
    })
  ),
  user_product_type: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  weight_uom: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  well_type: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      type: PropTypes.number,
      type_display: PropTypes.string,
    })
  ),
});

export const productNominalDetailsShape = PropTypes.shape({
  criteria: PropTypes.shape({
    od: PropTypes.number,
    wall: PropTypes.number,
    min_yield: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    api_internal_pressure: PropTypes.number,
    inside_diameter: PropTypes.number,
    minimum_tensile_strength: PropTypes.number,
    collapse_pressure: PropTypes.number,
    special_drift: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    drift: PropTypes.number,
    weight_per_foot: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    collapse_strength: PropTypes.number,
    body_yield_strength: PropTypes.number,
    weight_per_foot_upset: PropTypes.number,
    test_pressure: PropTypes.number,
    weight_per_foot_plain_end: PropTypes.number,
    yield_torque: PropTypes.number,
    area: PropTypes.number,
  }),
  result: PropTypes.shape({
    lot_summary: PropTypes.string,
  }),
  metallurgy: metallurgyShape,
  end_finish: PropTypes.shape({
    category: PropTypes.number,
    category_name: PropTypes.string,
    code: PropTypes.string,
    description: PropTypes.string,
    // TODO: define this properly when files are present
    // LINT OVERRIDE #6
    // Object has undetermined keys
    // eslint-disable-next-line react/forbid-prop-types
    files: PropTypes.array,
    id: PropTypes.number,
    licensee: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      slug: PropTypes.string,
    }),
    name: PropTypes.string,
    thread_type: PropTypes.number,
    thread_type_name: PropTypes.string,
  }),
});

export const facetMapShape = PropTypes.shape({
  facet: PropTypes.string,
  label: PropTypes.string,
});

export const alloyShape = PropTypes.shape({
  density: PropTypes.string,
  id: PropTypes.number,
  metallurgy_group: PropTypes.number,
  metallurgy_group_name: PropTypes.string,
  metallurgy_group_slug: PropTypes.string,
  name: PropTypes.string,
  path: PropTypes.string,
  rank: PropTypes.number,
  slug: PropTypes.string,
});

export const elementShape = PropTypes.shape({
  abbreviation: PropTypes.string,
  elementName: PropTypes.string,
  elementNumber: PropTypes.number,
  max: PropTypes.string,
  min: PropTypes.string,
});

export const valuesFormShape = PropTypes.shape({
  condition: PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
  }),
  manufacturer: PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
  }),
  manufacture_year: PropTypes.number,
  material_quality: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  manufacturing_process: PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
  }),
  supply_location_place_id: PropTypes.shape({
    value: PropTypes.string,
    place_id: PropTypes.string,
    label: PropTypes.string,
    description: PropTypes.string,
  }),
  yard_name: PropTypes.string,
  thread_configuration: PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
  }),
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  verified_date: PropTypes.instanceOf(Date),
  price_uom: PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
  }),
});

// yes itm :D
export const itmShape = PropTypes.shape({
  disabled: PropTypes.bool,
  name: PropTypes.string,
  component: PropTypes.string,
  sizes: PropTypes.shape({
    sm: PropTypes.number,
    lg: PropTypes.number,
    xl: PropTypes.number,
  }),
  type: PropTypes.string,
  prefix: PropTypes.string,
  currency: PropTypes.string,
  suffix: PropTypes.shape({
    name: PropTypes.string,
    key: PropTypes.string,
    placeholder: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        value: PropTypes.number,
        label: PropTypes.string,
      })
    ),
    style: PropTypes.shape({
      maxWidth: PropTypes.string,
    }),
  }),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    })
  ),
  key: PropTypes.string,
  placeholder: PropTypes.string,
  style: PropTypes.shape({
    maxWidth: PropTypes.string,
  }),
});

// Create a placeholder File class for Node
class FileMock {}

let _File;
if (canUseDOM) {
  _File = File;
} else {
  _File = FileMock;
}

export const fileUploadShape = PropTypes.shape({
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(_File)]),
  lastModified: PropTypes.number,
  lastModifiedDate: PropTypes.instanceOf(Date),
  name: PropTypes.string,
  path: PropTypes.string,
  result: PropTypes.string,
  size: PropTypes.number,
  type: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  uuid: PropTypes.string,
  webkitRelativePath: PropTypes.string,
});

export const contentMetadataShape = PropTypes.shape({
  description: PropTypes.string,
  image: PropTypes.number,
  slug: PropTypes.string,
  title: PropTypes.string,
  writer: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number,
  }),
  company: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number,
  }),
  co_company: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number,
  }),
  co_writer: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number,
  }),
});

export const rfqItemTechnicalDetailsShape = PropTypes.shape({
  application: PropTypes.string,
  burst_min: PropTypes.number,
  category_name: PropTypes.string,
  collapse_min: PropTypes.number,
  drift: PropTypes.number,
  implied_metallurgy_group_name: strArr,
  inside_diameter: PropTypes.number,
  metallurgy_name: strArr,
  metallurgy_group_name: strArr,
  min_yield: PropTypes.number,
  od: PropTypes.number,
  pren_min: PropTypes.number,
  range: PropTypes.string,
  wall: PropTypes.number,
  weight_per_foot: PropTypes.number,
  technical_inside_diameter_min: PropTypes.number,
  technical_od_max: PropTypes.number,
  tensile_strength_min: PropTypes.number,
  body_yield_strength: PropTypes.number,
  category: PropTypes.number,
  collapse_strength: PropTypes.number,
  footage: PropTypes.number,
  joints: PropTypes.number,
  id: PropTypes.number,
});

export const contentShape = PropTypes.shape({
  button_name: PropTypes.string,
  button_url: PropTypes.string,
  category: PropTypes.number,
  category_name: PropTypes.string,
  category_slug: PropTypes.string,
  co_company: PropTypes.oneOfType([PropTypes.number, PropTypes.shape({})]),
  co_company_name: PropTypes.string,
  co_writer: PropTypes.oneOfType([PropTypes.number, PropTypes.shape({})]),
  co_writer_name: PropTypes.string,
  company: PropTypes.oneOfType([PropTypes.number, PropTypes.shape({})]),
  company_name: PropTypes.string,
  content: PropTypes.shape({
    blocks: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        data: PropTypes.shape({}),
        text: PropTypes.string,
        type: PropTypes.string,
        depth: PropTypes.number,
        entityRanges: PropTypes.arrayOf(PropTypes.shape({})),
        inlineStyleRanges: PropTypes.arrayOf(PropTypes.shape({})),
      })
    ),
    entityMap: PropTypes.shape({}),
  }),
  create_timestamp: PropTypes.string,
  files: PropTypes.arrayOf(PropTypes.shape({})),
  id: PropTypes.number,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      image: PropTypes.string,
      original_file_name: PropTypes.string,
    })
  ),
  listings: PropTypes.arrayOf(PropTypes.shape({})),
  preview: PropTypes.shape({
    description: PropTypes.string,
    image: PropTypes.number,
    title: PropTypes.string,
  }),
  preview_image: PropTypes.number,
  products: PropTypes.arrayOf(PropTypes.shape({})),
  published_date: PropTypes.string,
  slug: PropTypes.string,
  status: PropTypes.string,
  submitter: PropTypes.number,
  submitter_name: PropTypes.string,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      id: PropTypes.number,
      name: PropTypes.string,
      slug: PropTypes.string,
    })
  ),
  update_timestamp: PropTypes.string,
  writer: PropTypes.oneOfType([PropTypes.number, PropTypes.shape({})]),
  writer_name: PropTypes.string,
});

export const pluginPropsShape = PropTypes.shape({
  category: PropTypes.number,
  contentType: PropTypes.number,
  files: PropTypes.arrayOf(PropTypes.shape({})),
  images: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      image: PropTypes.string,
      original_file_name: PropTypes.string,
    })
  ),
  listings: PropTypes.arrayOf(PropTypes.shape({})),
  objectId: PropTypes.number,
  products: PropTypes.arrayOf(PropTypes.shape({})),
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      id: PropTypes.number,
      name: PropTypes.string,
      slug: PropTypes.string,
    })
  ),
});
