import { canUseDOM } from 'exenv';
import {
  merge,
  isObject,
  isArray,
  reduce,
  isString,
  map,
  split,
  trim,
  replace,
  isEmpty,
  includes,
} from 'lodash';

function getCookie(name) {
  let cookieValue = null;
  if (canUseDOM && document && document.cookie && document.cookie !== '') {
    const cookies = split(document.cookie, ';');
    for (let i = 0; i < cookies.length; i += 1) {
      const cookie = trim(cookies[i]);
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === `${name}=`) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

// Avoiding recursion from above - dup code but with rootKey - Business Information onboarding passes back an object as error.
function generateErrorMessageFromObject(rootKey, errorMessage) {
  const rootKeyCopy = replace(rootKey, 'error.msg', 'Error');
  try {
    const array = map(errorMessage, (v, key) => {
      if (isArray(v)) {
        const reduceValues = reduce(
          v,
          (accum, value) => {
            if (isString(value)) {
              accum.push(`${Number.isNaN(key) ? `"${key}"` : ''} ${value}`);
            }
            return accum;
          },
          []
        );
        return [`${rootKeyCopy}: ${reduceValues.join(', ')}`];
      }
      if (isString(v)) {
        return [`${rootKeyCopy}: ${v}`];
      }
      return null;
    });
    return array.join(', ');
  } catch (err) {
    return 'An Unknown Error Occurred Determining Message';
  }
}

function generateErrorMessage(errorMessage) {
  if (!isEmpty(errorMessage)) {
    try {
      const array = map(errorMessage, (v, key) => {
        if (isObject(v) && !isArray(v)) {
          return generateErrorMessageFromObject(key, v);
        }
        if (isArray(v)) {
          const reduceValues = reduce(
            v,
            (accum, value) => {
              if (isString(value)) {
                accum.push(value);
              }
              return accum;
            },
            []
          );
          return [`${key}: ${reduceValues.join(', ')}`];
        }
        if (isString(v)) {
          return [`${key}: ${v}`];
        }
        return null;
      });
      return array.join(', ');
    } catch (err) {
      return 'An Unknown Error Occured Determining Message';
    }
  }
  return false;
}

const supportedTypes = ['json', 'blob'];
const typeToResponseFn = {
  json: (resp) => resp.json(),
  blob: (resp) => resp.blob(),
};

export default async function fetchJSON(
  url,
  fetchOptions = {},
  { formData } = {},
  type = 'json'
) {
  if (!includes(supportedTypes, type)) {
    throw new Error(`Unsupported type: ${type}`);
  }

  if (canUseDOM && window.OFFLINE_MODE) {
    return Promise.resolve({});
  }

  const requestHeaders = {};
  if (!formData) {
    // when sending formData browser automatically generates type
    requestHeaders['Content-Type'] = 'application/json';
  }

  const csrfToken = getCookie('csrftoken');
  if (csrfToken) {
    requestHeaders['X-CSRFToken'] = csrfToken;
  }

  // TODO - explore cookies with credentials
  const options = merge(
    {
      headers: requestHeaders,
    },
    fetchOptions
  );

  if (fetchOptions.credentials === false) {
    delete options.credentials;
  } else {
    options.credentials = 'include';
  }

  const resp = await fetch(url, options);
  if (resp.ok) {
    try {
      return await typeToResponseFn[type](resp);
    } catch (err) {
      // HANDLE WHEN RESPONSE IS BLANK BUT 200
      return { success: true };
    }
  } else {
    let errorMessage = '';

    if (resp.status >= 500) {
      errorMessage =
        'We are experiencing difficulties, please try again later or contact support.';
    } else if (resp.status === 403) {
      errorMessage =
        'Authentication failed. You are not authorized to perform this action.';
    } else {
      errorMessage = await resp.json();
      if (errorMessage && errorMessage.non_field_errors) {
        errorMessage = errorMessage.non_field_errors.join(', ');
      } else if (errorMessage && errorMessage.errors) {
        errorMessage = map(errorMessage.errors, (e) => e.detail || '').join(
          ' -- '
        );
      } else if (errorMessage && errorMessage.detail) {
        errorMessage = errorMessage.detail;
      } else if (isObject(errorMessage)) {
        errorMessage =
          generateErrorMessage(errorMessage) ||
          'An Unknown Error Occurred Generating Error Message';
      } else {
        errorMessage = 'An Unknown Error Occurred';
      }
    }
    const newError = new Error(errorMessage);
    newError.status = resp.status;
    throw newError;
  }
}
