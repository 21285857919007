import React from 'react';
import { MD, SM } from '@zendeskgarden/react-typography';
import { map, replace } from 'lodash';
import { Dot } from 'theme/Global.styles';
import Flex from 'styled-flex-component';
import moment from 'moment';
import { Link } from 'react-router-dom';
import objectToUrl from 'utility/notificationLinks';
import { PropTypes } from 'prop-types';

import {
  StyledNotificationDropdownList,
  StyledNotificationItem,
  StyledNotificationText,
  StyledNotificationSubject,
} from './NotificationDropdownList.styles';

function NotificationDropdownList({ onClickItem, notifications, paddings }) {
  return (
    <StyledNotificationDropdownList padding={paddings?.padding}>
      {notifications && notifications.length ? (
        map(notifications, (notification, i, arr) => (
          <NotificationItem
            last={i === arr.length - 1}
            onClickItem={onClickItem}
            key={i}
            notification={notification}
            padding={paddings}
          />
        ))
      ) : (
        <MD center style={{ paddingTop: '30px' }}>
          No Notifications
        </MD>
      )}
    </StyledNotificationDropdownList>
  );
}

function NotificationItem({ last, onClickItem, notification, padding }) {
  const {
    actor_count: actorCount,
    activity_count: activityCount,
    activities,
    updated_at: updatedAt,
    is_seen: isSeen,
  } = notification;
  const activity = activities[0];
  const isNew = !isSeen;

  const objectTypeReplaced = replace(
    replace(activity.object_type || '', 'AuthUser', 'User'),
    'SupplyOpportunity',
    'Supply'
  );
  const objectNameReplaced = replace(
    replace(activity.object_name || '', 'AuthUser', 'User'),
    'SupplyOpportunity',
    'Supply'
  );

  return (
    <StyledNotificationItem
      style={last ? { borderBottom: 0 } : {}}
      new
      padding={padding?.itemPadding}
    >
      <Link onClick={onClickItem} className="bold" to={objectToUrl(activity)}>
        <Flex full>
          {isNew ? (
            <Dot style={{ height: '24px' }} />
          ) : (
            <Dot style={{ height: '24px' }} background="#ccc" />
          )}
          <div style={{ flex: 1, minWidth: 0 }}>
            <Flex style={{ height: 'auto' }} full>
              <Flex full alignCenter>
                {actorCount === 1 ? (
                  <StyledNotificationSubject bold>
                    {activity.actor_name} made {activityCount}{' '}
                    {activityCount === 1 ? 'change' : 'changes'} to{' '}
                    {objectTypeReplaced} {objectNameReplaced}
                  </StyledNotificationSubject>
                ) : (
                  <StyledNotificationSubject bold>
                    {actorCount} {actorCount === 1 ? 'person' : 'people'} made{' '}
                    {activityCount} {activityCount === 1 ? 'change' : 'changes'}{' '}
                    to {objectTypeReplaced} {objectNameReplaced}
                  </StyledNotificationSubject>
                )}
              </Flex>
            </Flex>
            <StyledNotificationText ellipsis grey>
              <SM
                slate
                style={{ minWidth: '80px', textAlign: 'right' }}
                alignCenter
                bold={isNew}
                blue={isNew}
              >
                {moment.utc(updatedAt).local().format('h:mm a')}
              </SM>
            </StyledNotificationText>

            <SM paddingTopSm blue link>
              View {objectTypeReplaced}
            </SM>
          </div>
        </Flex>
      </Link>
    </StyledNotificationItem>
  );
}

NotificationDropdownList.propTypes = {
  onClickItem: PropTypes.func,
  notifications: PropTypes.arrayOf(PropTypes.shape({})),
  paddings: PropTypes.shape({
    padding: PropTypes.string,
    itemPadding: PropTypes.string,
  }),
};

NotificationDropdownList.defaultProps = {
  onClickItem: undefined,
  notifications: undefined,
  paddings: undefined,
};

NotificationItem.propTypes = {
  last: PropTypes.bool,
  onClickItem: PropTypes.func,
  notification: PropTypes.shape({
    actor_count: PropTypes.string,
    activity_count: PropTypes.string,
    activities: PropTypes.bool,
    updated_at: PropTypes.string,
    is_seen: PropTypes.bool,
  }),
  padding: PropTypes.shape({
    padding: PropTypes.string,
    itemPadding: PropTypes.string,
  }),
};

NotificationItem.defaultProps = {
  last: undefined,
  onClickItem: undefined,
  notification: undefined,
  padding: undefined,
};

export default NotificationDropdownList;
