import { map, get, cloneDeep, slice } from 'lodash';
import tree from 'state';
import * as effects from './effects';

export async function setRoutedModalIsAnimating(state) {
  tree.set(['application', 'routedModalIsAnimating'], state);
}

export async function setGlobalSearchStatus(state) {
  tree.set(['application', 'searchStatus'], state);
}

export async function setRoutedModalState(state, { element, transitioning }) {
  if (element && transitioning === undefined) {
    tree.set(
      ['application', 'routedModalState'],
      state === 'exit' ? 'exit' : 'exited'
    );
  }
  if (element && transitioning === false) {
    tree.set(
      ['application', 'routedModalState'],
      state === 'enter' ? 'enter' : 'entered'
    );
  }
  tree.commit();
}

export async function fetchPublicMeta() {
  const hasMeta = tree.get(['application', 'publicMeta']);
  if (hasMeta) {
    return hasMeta;
  }
  const meta = await effects.fetchPublicMeta();
  if (meta) {
    tree.set(['application', 'publicMeta'], meta);
  }
  return meta;
}

function engagementAutoCompleteFormatter(engagement) {
  const out = {};
  out.value = get(engagement, 'pk');
  out.label = get(engagement, 'title');

  if (engagement.engagement_company_names) {
    out.label += ` - ${engagement.engagement_company_names.join(', ')}`;
  }

  return out;
}

// each `type` of autocomplete suggestion needs its own formatter
export async function getSuggestionV2(
  type,
  value,
  { includeContacts, raw, clientId, content_type_id: contentTypeId } = {}
) {
  let _value = value;
  try {
    if (_value === undefined) {
      _value = '';
    }

    const { results, docs } = await effects.fetchSuggestion(
      type,
      _value,
      clientId,
      includeContacts,
      contentTypeId
    );
    return raw
      ? results || docs
      : map(results || docs, (r) => {
          let out = {};
          switch (type) {
            case 'engagement':
              out = engagementAutoCompleteFormatter(r);
              break;
            default:
              console.log('unsupported type in `getSuggestionV2`');
              return { error: 'unsupported type in `getSuggestionV2`' };
          }
          return out;
        });
  } catch (err) {
    console.error(err.message);
    return {
      error: err.message,
    };
  }
}

// getSuggestionV2 should be used instead of getSuggestion
// all uses of this function should use `getSuggestionV2` PIP-1203
export async function getSuggestion(
  type,
  value,
  {
    includeContacts,
    raw,
    getter,
    clientId,
    valueKey,
    labelKey,
    content_type_id: contentTypeId,
    isUser,
    contactStatus,
    company,
    labelFunc,
  } = {}
) {
  try {
    if (get(value, 'length') < 2 && value !== undefined) {
      return undefined;
    }

    const { results, docs } = await effects.fetchSuggestion(
      type,
      value,
      clientId,
      includeContacts,
      contentTypeId,
      isUser,
      contactStatus,
      company
    );
    // eslint-disable-next-line consistent-return
    return raw
      ? results || docs
      : map(results || docs, (result) => {
          const _result = cloneDeep(result);
          _result.value =
            _result[valueKey] ||
            _result.id ||
            _result.pk ||
            _result.value ||
            _result[getter || type] ||
            _result.slug;
          _result.label =
            (labelFunc && labelFunc(result)) ||
            _result[labelKey] ||
            _result[getter || type] ||
            _result.name ||
            _result.slug;
          return _result;
        });
  } catch (err) {
    console.error(err.message);
    // eslint-disable-next-line consistent-return
    return {
      error: err.message,
    };
  }
}

export async function addressSuggestion(address) {
  try {
    if (address.length < 2) return undefined;

    const { results } = await effects.fetchAddressSuggestion(address);
    return map(results, (result) => {
      const _result = cloneDeep(result);
      _result.value = _result.description;
      _result.label = _result.description;
      return _result;
    });
  } catch (err) {
    return {
      error: err.message,
    };
  }
}

export async function fileProxy(endpoint) {
  try {
    const file = await effects.fetchFileProxy(endpoint);
    return file;
  } catch (err) {
    return {
      error: err.message,
    };
  }
}

// IMAGE AND DOC UPLOAD
export async function setStagedUploads(data) {
  tree.set(['application', 'dropZone', 'stagedUploads'], data);
}

export async function createFile(data, options) {
  try {
    const file = await effects.createFile(data, options);
    return file;
  } catch (err) {
    return {
      error: err.message,
    };
  }
}

export async function manageFile(data, options) {
  try {
    const file = await effects.manageFile(data, options);
    return file;
  } catch (err) {
    return {
      error: err.message,
    };
  }
}

export async function deleteFile(id, options) {
  // fetchFile can now take a key in options of legacy to retrieve legacy files
  //  options = {legacy: true}
  try {
    const file = await effects.deleteFile(id, options);
    return file;
  } catch (err) {
    return {
      error: err.message,
    };
  }
}

export async function fetchFilesForObjectId(
  { objectId, contentType, type },
  options
) {
  try {
    const file = await effects.fetchFilesForObjectId(
      { objectId, contentType, type },
      options
    );
    return file;
  } catch (err) {
    return {
      error: err.message,
    };
  }
}

// IMAGE AND DOC UPLOAD

export async function fetchFile(id, options) {
  // fetchFile can now take a key in options of legacy to retrieve legacy files
  //  options = {legacy: true}
  try {
    const file = await effects.fetchFile(id, options);
    return file;
  } catch (err) {
    return {
      error: err.message,
    };
  }
}

export async function submitContactUs(data) {
  try {
    const contactUs = await effects.submitContactUs(data);
    return contactUs;
  } catch (err) {
    return {
      error: err.message,
    };
  }
}

export async function submitQuickInquire(data) {
  try {
    const contactUs = await effects.submitQuickInquire(data);
    return contactUs;
  } catch (err) {
    return {
      error: err.message,
    };
  }
}

export async function submitSubscribe(data) {
  try {
    const subscribe = await effects.submitSubscribe(data);
    return subscribe;
  } catch (err) {
    return {
      error: err.message,
    };
  }
}

export async function addContentImage(data) {
  console.log(data);
}

export function saveBulkEditConfig(config, customPath = []) {
  tree.set(['application', 'bulkEdit', 'config', ...customPath], config);
}

export function clearBulkEditConfig(customPath = []) {
  tree.unset(['application', 'bulkEdit', 'config', ...customPath]);
}

// AG Grid
export async function replaceResultsWithRowGroups(
  cursorPath,
  branchPath,
  resultsBranchPath,
  groupData,
  currentData
) {
  const cursor = tree.select(cursorPath);
  cursor.set(
    resultsBranchPath,
    slice(groupData, currentData.startRow, currentData.endRow)
  );
  cursor.set([...branchPath, 'rowCount'], groupData.length);
  const limit = cursor.get([...branchPath, 'limit']);
  const page = currentData.endRow / limit;
  cursor.set([...branchPath, 'page'], page);
  cursor.set([...branchPath, 'pagination', 'page'], page);
  tree.commit();
  const result = cursor.get(branchPath);
  return result;
}

// EXTERNAL TREE
export async function setFilters({ path, selected }) {
  // Clone filters so we don't accidentally mutate a passed object
  const _selected = cloneDeep(selected);
  tree.set(path, _selected);
  tree.commit();
}
